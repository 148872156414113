import React from 'react';
import styles from './ShareLink.module.scss';
import callToast from '../../blocks/ToastMessage/_parts/callToast/callToast';
import classNames from 'classnames';

interface ShareLinkProps extends React.PropsWithChildren {
  url: string; // URL to share
  title: string; // Title to share
  summary?: string; // Summary (for LinkedIn)
  shareFor: 'Facebook' | 'LinkedIn' | 'Twitter' | 'Email';
  invisible?: boolean;
}

const ShareLink: React.FC<ShareLinkProps> = ({
  url,
  title,
  summary = 'Summary...',
  shareFor,
  invisible = true,
  children,
}) => {
  const encodeURIComponent = (str: string) => window.encodeURIComponent(str);

  const handleEmailOnClick = () => {
    callToast({
      variation: 'info',
      title: 'Share via email',
      children: <>Nothing might happen. It might depend on you OS settings.</>,
    });
  };

  switch (shareFor) {
    case 'Facebook':
      return (
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(
            styles['link'],
            invisible ? styles[`link--invisible-true`] : styles[`link--invisible-false`]
          )}
        >
          {children}
        </a>
      );
    case 'LinkedIn':
      return (
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            url
          )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}&source=${encodeURIComponent(
            url
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(
            styles['link'],
            invisible ? styles[`link--invisible-true`] : styles[`link--invisible-false`]
          )}
        >
          {children}
        </a>
      );
    case 'Twitter':
      return (
        <a
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(
            styles['link'],
            invisible ? styles[`link--invisible-true`] : styles[`link--invisible-false`]
          )}
        >
          {children}
        </a>
      );
    case 'Email':
      return (
        <a
          href={`mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
            summary
          )}%0A%0A${encodeURIComponent(url)}`}
          className={classNames(
            styles['link'],
            invisible ? styles[`link--invisible-true`] : styles[`link--invisible-false`]
          )}
          onClick={handleEmailOnClick}
        >
          {children}
        </a>
      );
    default:
      return <></>;
  }
};

export default ShareLink;
