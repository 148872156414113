import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as GrowIll } from '../../asset/images/grow_ill.svg';
import { ReactComponent as GrowIll2 } from '../../asset/images/grow_ill_2.svg';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import CardsWithHeaderSection from '../../components/sections/CardsWithHeaderSection/CardsWithHeaderSection';
import MainButton from '../../components/buttons/MainButton/MainButton';
import PriceBillet from '../../components/blocks/PriceBillet/PriceBillet';

import { ReactComponent as MailIcon } from '../../asset/images/icons/mail.svg';
import { ReactComponent as PersonIcon } from '../../asset/images/icons/person.svg';
import { ReactComponent as ContractIcon } from '../../asset/images/icons/contract.svg';
import { ReactComponent as RetainerIcon } from '../../asset/images/icons/retainer.svg';

import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import Grid from '../../components/blocks/Grid/Grid';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import BuildFiltersWithServiceCards from '../../components/blocks/BuildFiltersWithServiceCards/BuildFiltersWithServiceCards';
import RoutesEnum from '../../enums/routes';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import GrowFiltersWithServiceCards from '../../components/blocks/GrowFiltersWithServiceCards/GrowFiltersWithServiceCards';
import { useAppSelector } from '../../redux/hooks';
import growPageContent from '../../data/grow';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import { GENERAL_CONTENT } from '../../data/generalContent';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import MetaTags from '../../components/seo/MetaTags/MetaTags';
import StandardAndRocketFuelPricing from '../../components/blocks/StandardAndRocketFuelPricing/StandardAndRocketFuelPricing';

interface BuildViewProps extends IWithUserRoleProps {}

const BuildView: FunctionComponent<BuildViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplateForMainPages>
      <MetaTags title="Grow" description={growPageContent[user_role]} />

      <HeroSection
        secondaryTitle="Grow"
        title="Prospect customers and investors with B2B data"
        text={growPageContent[user_role]}
        sectionVariation="var-1"
        illustration={<GrowIll style={{ maxHeight: '400px' }} />}
      />

      <SectionDivider color="Background-Neutral-Primary" />

      <CardsWithHeaderSection
        sectionVariation="var-1"
        sectionBackgroundColor="Background-Neutral-Primary"
        wrapperClass="type-1"
        title="Prospect your customers 📈"
        text="Tap into our database of over 100M B2B contacts for prospecting and connecting."
        button={
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              Join Astronomic for free
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              Schedule a meeting
            </MainButton>
          )
        }
        customElement={
          // <PriceBillet
          //   price="$0.16-$0.25"
          //   linkText="How hourly billing works"
          //   textAfter="/row"
          //   linkUrl={`/${user_role}/${RoutesEnum.PRICING}`}
          // />

          <StandardAndRocketFuelPricing
            sizes="var-a"
            hideRocketFuel={true}
            content={{
              standard: {
                value: '$0.10',
                valueAfter: '/row',
              },
              rocketFuel: {},
            }}
          />
        }
      >
        <GrowFiltersWithServiceCards userRole={user_role} />
      </CardsWithHeaderSection>

      <SectionDivider color="Background-Neutral-Secondary" />

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle secTitle="Verified contacts" title="Connect Confidently" />}
        backgroundColor="Background-Neutral-Secondary"
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Better together"
              text="It's lonely at the top, but not here. Share your challenges, goals, and questions with other founders, learn from their experiences, and develop rewarding friendships in the process."
              icon={<MailIcon style={{ maxWidth: '29px' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Accountability"
              text="It’s one thing to set your own goals, but it’s another thing to verbalize them to your peers. Set growth goals and let fellow founders hold you accountable to achieve them."
              icon={<PersonIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Skills"
              text="Gain valuable education and level-up your business skills, thanks to the insights shared by fellow founders who’ve been there/done that."
              icon={<ContractIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Growth"
              text="Track your success over time, build valuable connections, and take your business to the next level thanks to the power of Astronomic Network."
              icon={<RetainerIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <SectionDivider color="blue" />

      <HeroSection
        title="Request a Custom B2B Database"
        text="We offer custom B2B databases tailored to your needs for effective customer and investor prospecting. Upon registration, you can schedule a kickoff call with our team to discuss your requirements, and we'll create a database with essential information to help you succeed in your business ventures."
        sectionVariation="var-1-c-4"
        wrapperClass={null}
        illustration={<GrowIll2 />}
        buttonGroup={[
          !isUserLoggedIn ? (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
            </MainButton>
          ) : (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              {GENERAL_CONTENT.SCHEDULE_A_MEETING}
            </MainButton>
          ),
        ]}
      />

      {/* <SectionDivider color="gray" />

      <TestimonialsSection backgroundColor="gray" /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(BuildView);
