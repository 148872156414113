import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './SectionDivider.module.scss';

type ColorType =
  | 'white'
  | 'gray'
  | 'blue'
  | 'blue-dark'
  | 'Background-Neutral-Primary'
  | 'Background-Neutral-Secondary';

const colorMap: Record<ColorType, string> = {
  white: 'section-divider--color-white',
  gray: 'section-divider--color-gray',
  blue: 'section-divider--color-blue',
  'blue-dark': 'section-divider--color-blue-dark',
  'Background-Neutral-Primary': 'section-divider--color-neutral-primary',
  'Background-Neutral-Secondary': 'section-divider--color-neutral-secondary',
};

interface SectionDividerProps {
  className?: string;
  color?: ColorType;
  style?: React.CSSProperties;
}

const SectionDivider: FunctionComponent<SectionDividerProps> = ({ className, color = 'white', style }) => {
  return (
    <div
      className={classNames('section-divider', styles['section-divider'], styles[colorMap[color]], className)}
      style={style}
    >
      <svg width="1440" height="96" viewBox="0 0 1440 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1440 96.5H1440.5V96V-7.62939e-06V-0.524803L1439.98 -0.49942L-0.0241699 69.2213L-0.5 69.2443V69.7207V96V96.5H0H1440Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </div>
  );
};

export default SectionDivider;
