import { FunctionComponent, useEffect, useState } from 'react';
import styles from './WelcomeView.module.scss';
import classNames from 'classnames';
import BackgroundSectionTemplate from '../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import { ReactComponent as Logo } from '../../asset/images/astronomic_logo_3.svg';
import { ReactComponent as BG } from '../../asset/images/welcome_bg.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { getUserInfoAsyncThunk } from '../../redux/slices/user/thunks';
import { localStorageGetUserAuthItems } from '../../utils/localStorageMethods';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface WelcomeViewProps {}

const WelcomeView: FunctionComponent<WelcomeViewProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loadingProgress, setLoadingProgress] = useState(0);

  const { userId } = localStorageGetUserAuthItems();

  const isLoadingFinished = () => {
    return loadingProgress === 100 ? true : false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        const increment = Math.random() * 10;
        const newProgress = prevProgress + increment;
        return newProgress <= 90 ? newProgress : 90;
      });
    }, 200);

    const timeout = setTimeout(async () => {
      setLoadingProgress(100);
      clearTimeout(timeout);
      clearInterval(interval);

      if (userId) {
        await dispatch(getUserInfoAsyncThunk(userId));
      }

      console.log('USER INFO IS HERE WOHOO!');

      setTimeout(() => navigate('/account'), 2000);
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <section className={classNames(styles['section'])}>
      <MetaTags title="Welcome" />

      <BackgroundSectionTemplate color="blue">
        <BG className={styles['bg']} />
      </BackgroundSectionTemplate>

      <div className={classNames('wrapper')}>
        <NavLink className={styles['logo']} to={`/`}>
          <Logo />
        </NavLink>

        <div className="content">
          <div className={styles['info']}>
            <h1 className={classNames(styles['title'], 'text text--h1')}>
              Let's ascend to <br /> the moon 🚀
            </h1>

            <p className={classNames(styles['text'], 'text text--body-1')}>We're creating your galaxy 🪐</p>

            <div className={styles['loading-bar']}>
              <div className={styles['loading-bar__progress']} style={{ width: `${loadingProgress}%` }}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeView;
