import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './TitleWithElem.module.scss';

type MarginSizeType = 'null' | 'small' | 'medium' | 'large' | 'large_2';

const TitleWithElem: FunctionComponent<TitleWithElemProps> = ({
  className,
  marginTop = 'null',
  marginBottom = 'small',
  element,
  children,
}) => {
  return (
    <div
      className={classNames(
        styles['title-with-element'],
        styles[marginBottomMap[marginBottom]],
        styles[marginTopMap[marginTop]],
        className
      )}
    >
      <div className={classNames(styles['title-with-element__text'])}>{children}</div>

      <div className={classNames(styles['title-with-element__some-element'])}>{element}</div>
    </div>
  );
};

const marginTopMap: Record<MarginSizeType, string> = {
  null: '',
  small: 'title-with-element--mar-top-small',
  medium: 'title-with-element--mar-top-medium',
  large: 'title-with-element--mar-top-large',
  large_2: 'title-with-element--mar-top-large-2',
};

const marginBottomMap: Record<MarginSizeType, string> = {
  null: '',
  small: 'title-with-element--mar-bot-small',
  medium: 'title-with-element--mar-bot-medium',
  large: 'title-with-element--mar-bot-large',
  large_2: 'title-with-element--mar-bot-large-2',
};

interface TitleWithElemProps extends PropsWithChildren {
  className?: string;
  marginTop?: MarginSizeType;
  marginBottom?: MarginSizeType;
  element?: React.ReactElement;
}

export default TitleWithElem;
