import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../../../../asset/images/astronomic_logo.svg';
import { ReactComponent as FbIcon } from './../../../../../asset/images/icons/fb.svg';
import { ReactComponent as TwIcon } from './../../../../../asset/images/icons/tw.svg';
import { ReactComponent as InIcon } from '../../../../../asset/images/icons/in.svg';
import styles from './Footer.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { UserRoleEnum } from '../../../../../enums/user';
import RoutesEnum from '../../../../../enums/routes';
import { useAppSelector } from '../../../../../redux/hooks';
import AstronomicLogo from '../../../../../components/brand/AstronomicLogo/AstronomicLogo';
import { GENERAL_CONTENT } from '../../../../../data/generalContent';

interface FooterProps {
  userRole: UserRoleEnum;
}

const Footer: FunctionComponent<FooterProps> = ({ userRole }) => {
  const navigate = useNavigate();
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <footer className={classNames(styles['footer'])}>
      <div className="background"></div>

      <div className="wrapper type-1">
        <div className="content">
          <div className={classNames(styles['header-part'])}>
            <div className={classNames(styles['header-part__grid'])}>
              <div className={classNames(styles['header-part__grid__col'], styles['header-part__grid__col-1'])}>
                <div className={classNames(styles['logo'])}>
                  <AstronomicLogo />
                </div>

                <div className={classNames(styles['socials-group'])}>
                  <div className={classNames(styles['socials-group__item'])}>
                    <NavLink to="https://www.facebook.com/AstronomicATX/" target="_blank" rel="nofollow">
                      <FbIcon />
                    </NavLink>
                  </div>

                  <div className={classNames(styles['socials-group__item'])}>
                    <NavLink to="https://twitter.com/astronomic" target="_blank" rel="nofollow">
                      <TwIcon />
                    </NavLink>
                  </div>

                  <div className={classNames(styles['socials-group__item'])}>
                    <NavLink to="https://www.linkedin.com/company/astronomic/mycompany/" target="_blank" rel="nofollow">
                      <InIcon />
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className={classNames(styles['header-part__grid__col'], styles['header-part__grid__col-2'])}>
                <div className={classNames(styles['title'], styles['title-1'])}>
                  <h5 className="text text--h5">{GENERAL_CONTENT.JOIN_ASTRONOMIC}</h5>
                </div>

                <div className={classNames(styles['text-1'])}>
                  {isUserLoggedIn === false ? (
                    <div className="text text--body-2">
                      Our mission is to enable as much entrepreneurship as possible.{' '}
                      <NavigationLink path={`/${RoutesEnum.SIGN_UP}`} sizeType="small">
                        Create your free account
                      </NavigationLink>{' '}
                      to accelerate your company’s ascent to the moon.
                    </div>
                  ) : (
                    <div className="text text--body-2">
                      Our mission is to enable as much entrepreneurship as possible. Now it is time accelerate your
                      company’s ascent to the moon.
                    </div>
                  )}
                </div>
              </div>

              <div className={classNames(styles['header-part__grid__col'], styles['header-part__grid__col-3'])}>
                <div className={classNames(styles['title'], styles['title-3'])}>
                  <h5 className="text text--h5">Resources</h5>
                </div>

                <div className={classNames(styles['link-list'])}>
                  <ul>
                    <li>
                      <NavLink to={`/${userRole}/partner`} className="text text--body-2">
                        Referrers and Resellers
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={classNames(styles['header-part__grid__col'], styles['header-part__grid__col-4'])}>
                <div className={classNames(styles['title'], styles['title-2'])}>
                  <h5 className="text text--h5">Company</h5>
                </div>

                <div className={classNames(styles['link-list'])}>
                  <ul>
                    <li>
                      <NavLink to={`/${userRole}/about`} className="text text--body-2">
                        About Us
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${userRole}/partner`} className="text text--body-2">
                        Partner with Astronomic
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${userRole}/pricing`} className="text text--body-2">
                        Pricing & Rocket Fuel
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames(styles['footer-part'])}>
            <div className={classNames(styles['footer-part__grid'])}>
              <div className={classNames(styles['footer-part__grid__col'], styles['footer-part__grid__col-1'])}></div>

              <div className={classNames(styles['footer-part__grid__col'], styles['footer-part__grid__col-2'])}>
                <p className="text text--tiny">
                  Copyright © 2020-{new Date().getFullYear()} Astronomic Inc. All rights reserved.
                </p>
              </div>

              <div className={classNames(styles['footer-part__grid__col'], styles['footer-part__grid__col-3'])}>
                <div className={classNames(styles['link-list-2'])}>
                  <ul>
                    <li>
                      <NavLink to={`/${RoutesEnum.TERMS_OF_USE}`} className="text text--tiny text--bold">
                        Terms of Service
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${RoutesEnum.PRIVACY_POLICY}`} className="text text--tiny text--bold">
                        Privacy Policy
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${RoutesEnum.COOKIE_POLICY}`} className="text text--tiny text--bold">
                        Cookie Policy
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
