import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './ComingSoonMessage.module.scss';

interface ComingSoonMessageProps {
  type?: 'default' | 'overlay';
  customMessage?: string;
  customMessageTwo?: string;
}

const ComingSoonMessage: FunctionComponent<ComingSoonMessageProps> = ({
  type = 'default',
  customMessage,
  customMessageTwo,
}) => {
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'C' || event.key === 'c') {
        setVisibility((prevValue) => !prevValue);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return visibility ? (
    <div className={classNames(styles['container'], styles[`container--type-${type}`])}>
      <div className={classNames(styles['text'], 'text text--h4')}>{customMessage ? customMessage : 'Coming soon'}</div>
      <div className={classNames(styles['text-2'], 'text text--body')}>{customMessageTwo && customMessageTwo}</div>
    </div>
  ) : null;
};

export default ComingSoonMessage;
