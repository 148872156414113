import { FunctionComponent, useEffect, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../dto/api';
import { sessionStorageGetItem, sessionStorageSetItem } from '../../utils/sessionStorageMethods';
import { SESSION_STORAGE_KEYS } from '../../enums/sessionStorageKeys';
import {
  getLastElementFromURL,
  stringToUserFriendlyURL,
  userFriendlyURLToString,
} from '../../utils/stringFormatMethods';
import ServiceDetailTemplate from '../../containers/templates/ServiceDetailTemplate/ServiceDetailTemplate';
import { getRoleIdByUserRole } from '../../utils/role';
import callToast from '../../components/blocks/ToastMessage/_parts/callToast/callToast';
import RoutesEnum from '../../enums/routes';
import { normalizeRelatedServices } from '../../utils/normalizeData';
import { UserRoleId } from '../../data/role';

interface FundDetailViewProps extends IWithUserRoleProps {}

const FundDetailView: FunctionComponent<FundDetailViewProps> = ({ user_role: userRole }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState<Record<string, any>>();
  const resourceId = location.state?.resourceId;

  const getFundResourceDetails = async () => {
    try {
      const response = await api.Content.getFundResourceById(resourceId);
      const responseData = response.data;

      if (responseData.success && responseData.data) {
        setData(responseData.data);
        console.log(responseData.data);
      } else {
        console.error('Error:', responseData.errors);

        callToast({
          title: 'Something went wrong',
          children: `We couldn't retrieve the data. Please try opening the corresponding page again from the services list.`,
          variation: 'error',
        });

        navigate(`/${userRole}/${RoutesEnum.FUND}`);
      }
    } catch (error) {
      console.error('Error: ', error);

      callToast({
        title: 'Something went wrong',
        children: `We couldn't retrieve the data. Please try opening the corresponding page again from the services list.`,
        variation: 'error',
      });

      navigate(`/${userRole}/${RoutesEnum.FUND}`);
    }
  };

  useEffect(() => {
    setData(undefined);
    getFundResourceDetails();
  }, [location]);

  return (
    <ServiceDetailTemplate
      userRole={userRole}
      title={data?.servicePageHeadline}
      description={data?.servicePageDescription}
      loading={data ? false : true}
    />
  );
};

export default with_user_role(FundDetailView);
