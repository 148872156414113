import { FunctionComponent } from 'react';
import ContentWithSidebar from '../../containers/templates/ContentWithSidebar/ContentWithSidebar';
import RoutesEnum from '../../enums/routes';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface TermsOfUseViewProps {}

const TermsOfUseView: FunctionComponent<TermsOfUseViewProps> = () => {
  return (
    <>
      <MetaTags title="Terms of Service for Astronomic" />
      <ContentWithSidebar title="Terms of Service for Astronomic" date="December 1, 2024">
        <p>
          Welcome to Astronomic. By accessing or using our services, you agree to comply with the following terms and
          conditions. This Terms of Service (“ToS”) applies to all services offered by Astronomic, including Astronomic
          Dinners, Supernova Dinners, Aurora Dinners, Galaxy Dinners, Constellation Dinners, Nebula Dinners, Astronomic
          Build, Astronomic Grow (collectively “the Company,” “we,” or “us”), and other professional and consulting
          services.
        </p>

        <h5>1. Acceptance of Terms</h5>
        <p>
          Your use of Astronomic services indicates acceptance of this ToS, along with any other policies or agreements
          referenced herein, including our Privacy Policy, accessible at:{' '}
          <a href={`/${RoutesEnum.PRIVACY_POLICY}`}>https://www.astronomic.com/{RoutesEnum.PRIVACY_POLICY}</a>. If you
          do not agree to these terms, you should not use our services.
        </p>

        <h5>2. Eligibility and Registration</h5>
        <p>
          User Accounts: Users may create free accounts on astronomic.com. Following signup, users will be invited to
          join Astronomic's Slack community to facilitate networking and knowledge-sharing.
        </p>

        <p>
          Account Deletion: Users may delete their Astronomic accounts at any time, which will result in forfeiture of
          any accumulated Rocket Fuel credits.
        </p>
        <p>
          Our services are available to users aged eighteen (18) and older. By registering, you confirm that the
          information provided is accurate and up-to-date, and you consent to updates to this ToS as required. Account
          security and confidentiality of credentials are your responsibility, and you agree to notify us immediately of
          any unauthorized account access.
        </p>

        <h5>3. Description of Services</h5>
        <p>
          Aurora Dinners: Organizes networking dinners for sales teams and their target sales leads at luxury homes
          across the US. Clients pay a fixed fee upfront.
        </p>
        <p>
          Supernova Dinners: Provides networking dinners for startup founders and fund GPs to meet investors and LPs.
          Events are held in luxury homes across the US with upfront client payment.
        </p>
        <p>
          Nebula Dinners: Curates dinners for founders and fund GPs to connect with business advisors, also held at
          luxury homes with a fixed fee charged upfront.
        </p>
        <p>
          Constellation Dinners: Hosts small, 6-person networking dinners for founders and investors in restaurants
          across the US. Participation is free.
        </p>
        <p>
          Galaxy Membership: Offers a membership community for accredited investors and executives, providing weekly
          networking dinners, access to events, and concierge services. Members pay an annual fee.
        </p>
        <p>
          Astronomic Build: Delivers professional services including business strategy, legal assistance, financial
          planning, design, software development, marketing, and consulting services. Services are billed hourly based
          on actual hours worked.
        </p>
        <p>
          Astronomic Grow: Resells contact data in CSV format, with pricing based on data quantity (per row) and prepaid
          by the client.
        </p>

        <p>
          Rocket Fuel: Astronomic offers a credit system called Rocket Fuel, unassociated with cryptocurrency or
          blockchain. Customers earn $5 in Rocket Fuel credits for every $100 spent on Astronomic products and services.
          Rocket Fuel credits, tracked in user dashboards on astronomic.com, are redeemable across all Astronomic
          services and have a 1:1 USD value.
        </p>
        <p>
          Prepaid Rocket Fuel: Customers may prepay for Rocket Fuel in one-time or monthly bundles. A $500 prepayment
          grants $515 in credits, a $1,000 prepayment grants $1,035 in credits, a $5,000 prepayment grants $5,200 in
          credits, and a $10,000 prepayment grants $10,500 in credits.
        </p>
        <p>
          Equity-Based Rocket Fuel Multipliers: Customers can grant equity to Astronomic for enhanced Rocket Fuel
          rewards. For 1% equity, customers earn $10 per $100 spent for 12 months; for 2%, $15 per $100 spent; for 3%,
          $25 per $100 spent.
        </p>
        <p>
          Non-Cashable: Rocket Fuel credits cannot be redeemed for cash; they exist solely as a digital credit system
          within Astronomic.
        </p>
        <p>
          Non-Transferable: Users cannot sell or transfer their Astronomic accounts, including Rocket Fuel credits, to
          others.
        </p>
        <p>
          Equity Alternatives for Rocket Fuel Bonuses: Freelancers and consultants without equity may not participate in
          equity-for-credit programs. For funds, Astronomic may consider GP shares, carry, or a portion of management
          fees in place of equity.
        </p>
        <p>Expiration: Rocket Fuel credits expire 12 months after they are earned if unused.</p>

        <p>
          Astronomic provides a range of services including networking events, investor dinners, consulting, and
          professional development programs. Specific terms apply to each of our services as follows:
        </p>

        <ul>
          <li>
            <b>Aurora, Nebula, and Supernova Dinners</b>: We organize private events connecting entrepreneurs and
            investors with curated guest lists, managed venues, catering, and event facilitation.
          </li>
          <li>
            <b>Galaxy Membership</b>: Members receive access to curated investment opportunities, Galaxy houses,
            fireside chats, and other events.
          </li>
          <li>
            <b>Professional Services:</b> Customized business solutions, project management, and other professional
            services as outlined in individual Statements of Work.
          </li>
        </ul>

        <p>
          Referral Program: Customers earn 10% of sales made to referrals, credited in Rocket Fuel for up to 12 months
          following the referral's initial purchase.
        </p>

        <p>
          Reselling Astronomic Products: Astronomic allows customers to resell its products and services, with clients
          free to set resale prices.
        </p>

        <h5>4. Payment Terms</h5>
        <p>
          Astronomic Build: Services are billed at an hourly rate with upfront estimates, invoiced based on actual hours
          and materials used.
        </p>
        <p>Astronomic Grow: Contact data is prepaid, and data is delivered as CSV files.</p>
        <p>Payment terms vary by service as follows:</p>

        <ul>
          <li>
            <b>Event-Based Services (Supernova, Aurora, Nebula)</b>: Full payment is required upon agreement to proceed
            with event curation and booking. If applicable, refunds are issued minus any non-refundable costs per the
            termination terms.
          </li>
          <li>
            <b>Membership Services (Galaxy)</b>: Membership renews annually unless canceled with seven (7) days’ notice
            before renewal. There are no refunds for membership fees after initiation.
          </li>
          <li>
            <b>Professional Services</b>: Services are billed at hourly rates detailed in the applicable Statement of
            Work, payable biweekly. Late payments incur a 2% monthly fee if overdue by more than 15 days.
          </li>
        </ul>

        <h5>5. Event Policies (Aurora, Supernova, Nebula, Galaxy Events)</h5>
        <p>For event-based services, we outline the following terms:</p>
        <ul>
          <li>
            <b>Cancellation by Client</b>: Cancellations must be in writing. Refunds exclude non-refundable costs such
            as deposits for catering, venues, and staffing fees, with an additional $2,500 retention fee.
          </li>
          <li>
            <b>Cancellation by Company</b>: We reserve the right to cancel for any behavior that violates company
            policies, including harassment or unprofessional conduct, with no refunds issued beyond non-refundable
            costs.
          </li>
          <li>
            <b>Force Majeure</b>: Neither party is liable for delays or cancellations due to unforeseeable events beyond
            control, such as natural disasters or government actions. Non-refundable expenses remain retained.
          </li>
        </ul>

        <h5>6. Intellectual Property</h5>
        <p>
          All proprietary content, materials, and intellectual property generated by Astronomic during service delivery
          remain the exclusive property of Astronomic. Clients may not use, modify, or distribute these materials
          without express consent. All provided materials are considered "works made for hire" for clients where
          applicable under U.S. copyright law.
        </p>

        <h5>7. Confidentiality and Non-Disclosure</h5>
        <p>
          Astronomic will not disclose or use clients' confidential information for any purpose outside of service
          delivery without written authorization. Confidential information includes all non-public, proprietary business
          information, disclosed orally or in writing, unless it becomes publicly known through no fault of Astronomic.
        </p>

        <h5>8. Code of Conduct and Behavior</h5>
        <p>
          Confidentiality: Attendees are required to maintain confidentiality of client information shared during
          events.
        </p>
        <p>
          Slack Workspace: Members are invited to engage professionally, and confidential business contacts shared
          within the workspace are for personal use only.
        </p>
        <p>For services involving events and Galaxy houses, all users must:</p>
        <ul>
          <li>Maintain professional behavior and respect other attendees.</li>
          <li>Abstain from drug use, smoking, or excessive drinking at events.</li>
          <li>Adhere to Galaxy house rules, including a quiet time of 10 PM.</li>
          <li>
            Refrain from harassing behavior; violations may result in immediate suspension or termination without
            refund.
          </li>
        </ul>

        <h5>9. Limitation of Liability</h5>
        <p>
          To the fullest extent permitted by law, Astronomic disclaims liability for any damages resulting from service
          interruptions, lost profits, data loss, or any form of indirect, special, or consequential damages. Direct
          liability, where applicable, is limited to the amount paid for the specific service giving rise to the claim.
        </p>

        <h5>10. Dispute Resolution</h5>
        <p>
          Any disputes arising from this ToS will first be submitted to mediation, followed by binding arbitration under
          the American Arbitration Association’s rules, with Texas law governing. Litigation will be limited to the
          courts of Travis County, Texas.
        </p>

        <h5>11. Governing Law</h5>
        <p>
          This ToS is governed by the laws of the state of Texas, without regard to its conflict of laws provisions. All
          legal proceedings shall take place within Texas, USA.
        </p>

        <h5>12. Changes to the Terms of Service</h5>
        <p>
          Astronomic reserves the right to modify these terms at its discretion, with all changes effective upon
          posting. Continued use of services following updates constitutes acceptance of the revised terms.
        </p>

        <h5>13. Contact Information</h5>
        <p>
          For questions regarding this ToS, please contact us at{' '}
          <a href="mailto:support@astronomic.com">support@astronomic.com</a>.
        </p>
      </ContentWithSidebar>
    </>
  );
};

export default TermsOfUseView;
