import { FunctionComponent, useEffect, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as LearnIll } from '../../asset/images/learn_ill.svg';
import MainButton from '../../components/buttons/MainButton/MainButton';
import CardsWithHeaderSection from '../../components/sections/CardsWithHeaderSection/CardsWithHeaderSection';
import Grid from '../../components/blocks/Grid/Grid';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';

import { ReactComponent as LensIcon } from '../../asset/images/icons/lens.svg';
import { ReactComponent as PersonIcon } from '../../asset/images/icons/person.svg';
import { ReactComponent as ContractIcon } from '../../asset/images/icons/contract.svg';
import { ReactComponent as RetainerIcon } from '../../asset/images/icons/retainer.svg';
import { ReactComponent as DepositIcon } from '../../asset/images/icons/deposit.svg';
import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import PriceBillet from '../../components/blocks/PriceBillet/PriceBillet';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import learnPageContent from '../../data/learn';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';
import api from '../../api';
import { getRoleIdByUserRole } from '../../utils/role';
import { stringToUserFriendlyURL, truncateString } from '../../utils/stringFormatMethods';
import PreloadProductCard from '../../components/blocks/PreloadProductCard/PreloadProductCard';
import MetaTags from '../../components/seo/MetaTags/MetaTags';
import StandardAndRocketFuelPricing from '../../components/blocks/StandardAndRocketFuelPricing/StandardAndRocketFuelPricing';

interface LearnViewProps extends IWithUserRoleProps {}

const LearnView: FunctionComponent<LearnViewProps> = ({ user_role: userRole }) => {
  const [learnMentors, setLearnMentors] = useState<any[] | undefined>([]);

  const getConnectPrograms = async () => {
    try {
      const response = await api.Content.getLearnMentorsByRole(getRoleIdByUserRole(userRole));
      const responseData = response.data;

      if (responseData.success && responseData.data) {
        setLearnMentors(responseData.data);
        console.log('LEARN DATA:', responseData.data);
      } else {
        console.error('Error:', responseData.errors);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    getConnectPrograms();
  }, [userRole]);

  return (
    <MainTemplateForMainPages>
      <MetaTags title="Learn" description={learnPageContent[userRole]} />

      <HeroSection
        secondaryTitle="Learn"
        title="Learn from a founder who’s built a company like yours"
        text={learnPageContent[userRole]}
        sectionVariation="var-1"
        illustration={<LearnIll style={{ maxHeight: '400px' }} />}
      />

      <SectionDivider color="Background-Neutral-Primary" />

      <CardsWithHeaderSection
        title="Learn"
        text="Learn from founders who’ve built companies just like yours."
        sectionBackgroundColor="Background-Neutral-Primary"
        sectionVariation="var-1"
        button={<JoinForFreeButton />}
        customElement={
          // <PriceBillet
          //   price="$160-$250"
          //   linkText="How hourly billing works"
          //   linkUrl={`/${userRole}/${RoutesEnum.PRICING}`}
          // />

          <StandardAndRocketFuelPricing
            sizes="var-a"
            hideRocketFuel={true}
            content={{
              standard: {
                value: '$500',
                valueAfter: '/hour',
              },
              rocketFuel: {},
            }}
          />
        }
      >
        {learnMentors && learnMentors.length > 0 ? (
          <Grid
            itemGroup={learnMentors?.map((item, i) => {
              return (
                <ServiceCard
                  key={item.id}
                  title={item.mentorName}
                  text={truncateString(item.description, 100)}
                  textBeforePrice=""
                  price={'Free to apply'}
                  link={`/${userRole}/${RoutesEnum.LEARN}/${stringToUserFriendlyURL(item.mentorName)}`}
                  navigateState={{ mentorId: item.id }}
                />
              );
            })}
            itemsInRow={3}
          />
        ) : (
          <Grid
            itemGroup={Array.from({ length: 3 }).map((_, i) => (
              <PreloadProductCard key={i} />
            ))}
          />
        )}
      </CardsWithHeaderSection>

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle secTitle="Expertise" title="Been there, done that " />}
        backgroundColor="Background-Neutral-Primary"
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Expert matches"
              text="We match you with founders who’ve built, sold, or operationalized businesses that match your industry, revenue stage, model, and growth goal, so that you’re always having relevant conversations."
              icon={<LensIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Patient helpers"
              text="We carefully vet our advisors to make sure they are kind, compassionate, and understanding so that they can be both great helpers but also great listeners."
              icon={<PersonIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="No long-term contracts"
              text="We use a repeating 7-day contract so that if you need to stop, you can stop on a dime without being locked into a long-term commitment.."
              icon={<ContractIcon />}
            />,
            <TitleWithIconAndTextCard
              title="No retainers"
              text="We only work hourly with no monthly retainers, which means you pay only for what you need and nothing more."
              icon={<RetainerIcon style={{ fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="No deposits"
              text="As long as you agree to our terms, we’ll get started working without you needing to put any money down."
              icon={<DepositIcon style={{ fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="No lead times"
              text="Need to get started ASAP? You got it. We can assemble a team and start your work within 24 hours."
              icon={<TimeIcon style={{ fill: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>
    </MainTemplateForMainPages>
  );
};

export default with_user_role(LearnView);
