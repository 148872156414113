import { FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import classNames from 'classnames';
import styles from './AccountBoost.module.scss';
import Label from '../../../../../components/texts/Label/Label';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import Grid from '../../../../../components/blocks/Grid/Grid';
import Countdown from '../../../../../components/blocks/Countdown/Countdown';
import { useAppSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../../../../enums/routes';
import MetaTags from '../../../../../components/seo/MetaTags/MetaTags';

interface AccountBoostProps {}

const itemList = [
  {
    property: 'Build',
    value: '$75/hour',
    boostedValue: '$50/hour',
  },
  {
    property: 'Learn',
    value: '$250/hour',
    boostedValue: '$150/hour',
  },
  {
    property: 'Fund',
    value: '$250/hour',
    boostedValue: '$150/hour',
  },
  {
    property: 'Grow',
    value: '$0.50/row',
    boostedValue: '$0.33/row',
  },
];

const AccountBoost: FunctionComponent<AccountBoostProps> = () => {
  const [accountIsBoosted, setAccountIsBoosted] = useState(false);
  const navigate = useNavigate();

  const boostEndDate = useAppSelector((state) => state.user.user?.boostEndDate);
  const equityPercentage = useAppSelector((state) => state.user.user?.equityPercentage);

  // THIS ONE IS FOR DEMO PURPOSE ONLY (DELETE AFTER PROPER IMPLEMENTATION)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'B' || event.key === 'b') {
        setAccountIsBoosted((prevValue) => !prevValue);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  // THIS ONE IS FOR DEMO PURPOSE ONLY (DELETE AFTER PROPER IMPLEMENTATION) END

  useEffect(() => {
    if (boostEndDate) {
      setAccountIsBoosted(true);
    }
  }, [boostEndDate]);

  return (
    <div className={styles['boost']}>
      <MetaTags title="Account - Boost" />

      <NavigationLink
        className={styles['boost__back-link']}
        path="/account/rocket-fuel"
        iconLeft={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
        withBorder={false}
      >
        Back to Membership
      </NavigationLink>

      <h3 className={classNames(styles['title'], 'text text--h3')}>
        {accountIsBoosted ? 'Account boosted' : 'Boost your account'}
      </h3>

      <div className={classNames(styles['boost-tile'], 'tile tile--padding-32px')}>
        <div className={styles['boost-tile__title-container']}>
          <p className={classNames(styles['boost-tile__title-container__title'], 'text text--body-1 text--bold')}>
            Astronomic account
          </p>

          <Label className={styles['boost-tile__title-container__label']} text="FREE" visualStyle="white" />
        </div>

        <p className={classNames(styles['boost-tile__text'], 'text text--body-2')}>
          Every user at Astronomic has a free account. You automatically gain access to our platform's essential
          services and resources to build your infrastructure and drive value for your company and customers.
        </p>

        <p className={classNames(styles['boost-tile__title-2'], 'text text--caps-small')}>Services cost</p>

        <div className={styles['boost-tile__list']}>
          <Grid
            itemsInRow={4}
            gridHorizontalMargin={1}
            gridVerticalMargin={3}
            itemGroup={itemList.map((item, i) => (
              <div className={styles['boost-tile__list__item']} key={i}>
                <p className={classNames(styles['boost-tile__list__item__property'], 'text text--caption text--bold')}>
                  {item.property}
                </p>

                <p className={classNames(styles['boost-tile__list__item__value'], 'text text--caption')}>
                  From{' '}
                  <span
                    className={classNames(
                      styles['boost-tile__list__item__value__mark'],
                      'text text--body-2 text--bold'
                    )}
                  >
                    {item.value}
                  </span>
                </p>
              </div>
            ))}
          />
        </div>
      </div>

      <div
        className={classNames(
          styles['boost-tile'],
          'tile tile--padding-32px',
          accountIsBoosted === true ? styles['boost-tile--boosted-active'] : styles['boost-tile--boosted-example']
        )}
      >
        <div className={styles['boost-tile__title-container']}>
          <p className={classNames(styles['boost-tile__title-container__title'], 'text text--body-1 text--bold')}>
            Boost
          </p>

          {equityPercentage && (
            <Label
              className={styles['boost-tile__title-container__label']}
              text={`${equityPercentage}% EQUITY`}
              visualStyle="white"
            />
          )}
        </div>

        {accountIsBoosted === false && (
          <p className={classNames(styles['boost-tile__text'], 'text text--body-2')}>
            Partner with us and we’ll forego margin. Grant equity to Astronomic and get a discount on our products and
            services for one year.
            <br />
            <br />
            Share 1% equity from your company to Astronomic and boost your account for 1 year. Receive a 10% discount on
            all Astronomic products and services as well as 2x the Rocket Fuel credits for 2% or a 25% discount for 3%.
          </p>
        )}

        {accountIsBoosted === true && (
          <p className={classNames(styles['boost-tile__text'], 'text text--body-2')}>
            Boost your account and unlock up to <b>33% discount on all services</b>, maximizing the value you receive.
            Additionally you will receive <b>double rocket fuel</b> credits when subscribing to any plan, giving you
            even more purchasing power for our services.
          </p>
        )}

        {accountIsBoosted === false && (
          <div className={styles['explain-list']}>
            <div className={styles['explain-list__item']}>
              <p className={classNames(styles['explain-list__title'], 'text text--caption text--bold')}>
                Share 1% and gain
              </p>

              <div className={styles['explain-list__item__tile-list']}>
                <div className={styles['explain-list__item__tile-list__item']}>
                  <p className={classNames(styles['explain-list__value'], 'text text--body-2 text--bold')}>2x</p>
                  <p className={classNames(styles['explain-list__property'], 'text text--caption text--bold')}>
                    Rocket Fuel
                  </p>
                </div>
              </div>
            </div>

            <div className={styles['explain-list__item']}>
              <p className={classNames(styles['explain-list__title'], 'text text--caption text--bold')}>
                Share 2% and gain
              </p>

              <div className={styles['explain-list__item__tile-list']}>
                <div className={styles['explain-list__item__tile-list__item']}>
                  <p className={classNames(styles['explain-list__value'], 'text text--body-2 text--bold')}>10%</p>
                  <p className={classNames(styles['explain-list__property'], 'text text--caption text--bold')}>
                    Discount
                  </p>
                </div>

                <div className={styles['explain-list__item__tile-list__item']}>
                  <p className={classNames(styles['explain-list__value'], 'text text--body-2 text--bold')}>3x</p>
                  <p className={classNames(styles['explain-list__property'], 'text text--caption text--bold')}>
                    Rocket Fuel
                  </p>
                </div>
              </div>
            </div>

            <div className={styles['explain-list__item']}>
              <p className={classNames(styles['explain-list__title'], 'text text--caption text--bold')}>
                Share 3% and gain
              </p>

              <div className={styles['explain-list__item__tile-list']}>
                <div className={styles['explain-list__item__tile-list__item']}>
                  <p className={classNames(styles['explain-list__value'], 'text text--body-2 text--bold')}>10%</p>
                  <p className={classNames(styles['explain-list__property'], 'text text--caption text--bold')}>
                    Discount
                  </p>
                </div>

                <div className={styles['explain-list__item__tile-list__item']}>
                  <p className={classNames(styles['explain-list__value'], 'text text--body-2 text--bold')}>5x</p>
                  <p className={classNames(styles['explain-list__property'], 'text text--caption text--bold')}>
                    Rocket Fuel
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <p className={classNames(styles['boost-tile__title-2'], 'text text--caps-small')}>Boosted Account</p>

        <div className={styles['boost-tile__list']}>
          <Grid
            itemsInRow={4}
            gridHorizontalMargin={1}
            gridVerticalMargin={3}
            itemGroup={itemList.map((item, i) => (
              <div className={styles['boost-tile__list__item']} key={i}>
                <p className={classNames(styles['boost-tile__list__item__property'], 'text text--caption text--bold')}>
                  {item.property}
                </p>

                <p className={classNames(styles['boost-tile__list__item__value'], 'text text--caption')}>
                  From{' '}
                  <span
                    className={classNames(
                      styles['boost-tile__list__item__value__mark'],
                      'text text--body-2 text--bold'
                    )}
                  >
                    {item.boostedValue}
                  </span>
                </p>
              </div>
            ))}
          />
        </div>

        {accountIsBoosted === false && (
          <p className={classNames(styles['boost-tile__text-2'], 'text text--caption')}>
            By clicking "Boost account," you agree to transfer 1% equity according to the{' '}
            <NavigationLink path={`/${RoutesEnum.TERMS_OF_USE}`} withBorder={true} sizeType="default">
              Terms and Conditions
            </NavigationLink>
            .
          </p>
        )}

        {accountIsBoosted === false && (
          <div className={styles['boost-tile__button-group']}>
            <MainButton visualType="white" onClick={() => navigate(`/founder/${RoutesEnum.PRICING}`)}>
              Learn more
            </MainButton>
            <MainButton onClick={() => navigate(`/founder/${RoutesEnum.SCHEDULE_MEETING}`)}>Boost account</MainButton>
          </div>
        )}

        {accountIsBoosted === true && (
          <footer className={styles['boost-tile__footer']}>
            <Countdown
              className={styles['boost-tile__footer__countdown']}
              startDate={new Date()}
              endDate={boostEndDate ? new Date(boostEndDate as Date) : new Date()}
            />
          </footer>
        )}
      </div>
    </div>
  );
};

export default AccountBoost;
