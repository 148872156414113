import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import styles from './AccountTemplate.module.scss';
import classNames from 'classnames';
import { UserRoleEnum } from '../../../enums/user';
import Header from '../MainTemplate/_parts/header/Header';
import Footer from '../MainTemplate/_parts/footer/Footer';
import { ReactComponent as DashboardIcon } from '../../../asset/images/icons/dashboard.svg';
import { ReactComponent as RocketIcon } from '../../../asset/images/icons/rocket.svg';
import { ReactComponent as LayersIcon } from '../../../asset/images/icons/layers.svg';
import { ReactComponent as PersonIcon } from '../../../asset/images/icons/person.svg';
import { ReactComponent as CardIcon } from '../../../asset/images/icons/card.svg';
import { ReactComponent as MainIcon } from '../../../asset/images/icons/mail.svg';
import { Outlet } from 'react-router-dom';
import BackgroundSectionTemplate from '../../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import SectionDivider from '../../../components/sections/SectionDivider/SectionDivider';
import AccountHeader from './_parts/AccountHeader/AccountHeader';
import AccountSidebar, { AccountSidebarLink } from './_parts/AccountSidebar/AccountSidebar';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import api from '../../../api';
import { isAxiosError } from 'axios';
import { setPaymentMethodId } from '../../../redux/slices/user/slice';
import MetaTags from '../../../components/seo/MetaTags/MetaTags';

interface AccountTemplateProps extends PropsWithChildren {}

const sidebarItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, url: '/account/dashboard' },
  {
    text: 'Rocket Fuel',
    icon: <RocketIcon style={{ width: '18px' }} />,
    url: '/account/rocket-fuel',
  },
  // {
  //   text: 'Subscriptions',
  //   icon: <LayersIcon />,
  //   url: '/account/subscription',
  // },
  {
    text: 'Apps',
    icon: <LayersIcon />,
    url: '/account/apps',
  },
  {
    text: 'Profile',
    icon: <PersonIcon style={{ width: '18px' }} />,
    url: '/account/profile',
  },
  {
    text: 'Billing',
    icon: <CardIcon style={{ width: '20px' }} />,
    url: '/account/billing',
  },
  // {
  //   text: 'Notifications',
  //   icon: <MainIcon style={{ width: '22px' }} />,
  //   url: '/account/notifications',
  // },
] as AccountSidebarLink[];

const AccountTemplate: FunctionComponent<AccountTemplateProps> = ({ children }) => {
  const user_role = UserRoleEnum['founder'].toLocaleLowerCase() as UserRoleEnum;

  return (
    <div className={classNames(styles['account-template'], 'template')}>
      <Header user_role={user_role} hideRoleSelector={true} loggedIn={true} headerColor="blue" />

      <MetaTags title="Account" />

      <AccountHeader />

      <SectionDivider color="Background-Neutral-Primary" />

      <main className={styles['main']}>
        <BackgroundSectionTemplate color="Background-Neutral-Primary" />

        <div className={classNames('wrapper', 'type-1')}>
          <div className="content">
            <div className={styles['main__part-list']}>
              <div className={classNames(styles['main__part-list__item'], styles['main__part-list__item-1'])}>
                <AccountSidebar items={sidebarItems} />
              </div>

              <div className={classNames(styles['main__part-list__item'], styles['main__part-list__item-2'])}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer userRole={user_role} />
    </div>
  );
};

export default AccountTemplate;
