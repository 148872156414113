import { FunctionComponent, useState, useEffect, useCallback, ReactElement } from 'react';
import classNames from 'classnames';
import ServiceCard, { ServiceCardProps } from '../ServiceCard/ServiceCard';
import Grid from '../Grid/Grid';
import RadioList, { RadioData } from '../../inputs/RadioList/RadioList';
import MainButton from '../../buttons/MainButton/MainButton';
import Input from '../../inputs/Input/Input';
import { ReactComponent as Chevron } from '../../../asset/images/chevron.svg';
import { UserRoleEnum } from '../../../enums/user';
import CheckboxList, { CheckboxData } from '../../inputs/CheckboxList/CheckboxList';
import styles from './BuildFiltersWithServiceCards.module.scss';
import api from '../../../api';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../dto/api';
import RoutesEnum from '../../../enums/routes';
import PreloadProductCard from '../PreloadProductCard/PreloadProductCard';
import CSSTransitionWrapper from '../../../containers/wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../AstronomicLoader/AstronomicLoader';
import { stringToUserFriendlyURL, truncateString } from '../../../utils/stringFormatMethods';
import { GENERAL_CONTENT } from '../../../data/generalContent';
import { getRoleIdByUserRole } from '../../../utils/role';

export interface BuildFiltersWithServiceCardsProps {
  userRole: UserRoleEnum;
}

const BuildFiltersWithServiceCards: FunctionComponent<BuildFiltersWithServiceCardsProps> = ({ userRole }) => {
  const [growthStage, setGrowthStage] = useState<Array<any>>([]);
  const [disciplines, setDisciplines] = useState<Array<any>>([]);
  const [services, setServices] = useState<Array<any>>();
  const [selectedGrowthStage, setSelectedGrowthStage] = useState('');
  const [selectedDisciplines, setSelectedDisciplines] = useState<Array<string>>();
  const [filterChanged, setFilterChanged] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loader, setLoader] = useState(false);

  const handleGrowthStageChange = (data) => {
    setSelectedGrowthStage(data.value);

    if (filterChanged === false) setFilterChanged(true);
  };

  const handleDisciplinesChange = (data) => {
    const mappedData = data.map((item) => item.value);
    setSelectedDisciplines(mappedData);

    if (filterChanged === false) setFilterChanged(true);
  };

  useEffect(() => {
    const getGrowthStagesMethod = async () => {
      try {
        const response = await api.Content.getGrowthStages();
        const responseData = response.data;

        if (responseData.success === true && responseData.data) {
          const mappedData = responseData.data.map((item, i) => ({
            // value: item.id,
            value: i,
            label: item.name,
          }));

          setGrowthStage(mappedData);
          setSelectedGrowthStage(mappedData[0]?.value);
        } else {
          console.error('Error:', responseData.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;
        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }
        console.error('Error: ', error);
      }
    };

    const getServiceCategoriesMethod = async () => {
      try {
        const response = await api.Content.getServiceCategories();
        const responseData = response.data;

        if (responseData.success === true && responseData.data) {
          const mappedData = responseData.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          setDisciplines(mappedData);
        } else {
          console.error('Error:', responseData.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;
        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }
        console.error('Error: ', error);
      }
    };

    getGrowthStagesMethod();
    getServiceCategoriesMethod();
  }, []);

  useEffect(() => {
    const timeoutForDebounce = setTimeout(
      () => {
        const getServices = async () => {
          if (firstLoad === false && filterChanged === true) setLoader(true);

          try {
            const response = await api.Content.getServicesByCategoryAndStage({
              roleId: getRoleIdByUserRole(userRole),
              disciplineId: selectedDisciplines as string[] | null,
              stage: selectedGrowthStage,
            });
            const responseData = response.data;

            if (responseData.success === true && responseData.data) {
              setServices(responseData.data);
              console.log('DATA:', responseData.data);
            } else {
              console.error('Error:', responseData.errors);
            }
          } catch (error) {
            const errorObj = error as AxiosError<ApiResponseDTO>;
            const errorData = errorObj.response?.data;
            if (errorData?.errorMessage) {
              console.error(errorData.errorMessage);
            }
            console.error('Error: ', error);
          } finally {
            setLoader(false);
          }
        };

        if (firstLoad === false) getServices();
      },
      filterChanged === false ? 0 : 1000
    );

    if (firstLoad === true && growthStage?.length > 0 && disciplines?.length > 0) {
      setFirstLoad(false);
      setLoader(false);
    } else {
      // setLoader(true);
    }

    console.log('reader');

    return () => clearTimeout(timeoutForDebounce);
  }, [selectedGrowthStage, selectedDisciplines, growthStage, disciplines, firstLoad, filterChanged, userRole]);

  return (
    <div className={classNames(styles['container'])}>
      <div className={classNames(styles['container__filters'])}>
        <div className={classNames(styles['container__filters__item'])}>
          <div className={classNames(styles['container__filters__item__title'])}>
            <h5 className="text text--h5">{GENERAL_CONTENT.BUILD_STAGES}</h5>
          </div>

          <div className={classNames(styles['container__filters__item__input-group'])}>
            {growthStage && (
              <RadioList
                name="growth_stage"
                data={growthStage}
                onChange={handleGrowthStageChange}
                checkedValue={growthStage[0]?.value as string}
              />
            )}
          </div>
        </div>

        <div className={classNames(styles['container__filters__item'])}>
          <div className={classNames(styles['container__filters__item__title'])}>
            <h5 className="text text--h5">Disciplines</h5>
          </div>

          <div className={classNames(styles['container__filters__item__input-group'])}>
            {disciplines && <CheckboxList data={disciplines} onChange={handleDisciplinesChange} name="disciplines" />}
          </div>
        </div>
      </div>

      <div className={classNames(styles['container__cards'])}>
        <div className={styles['loader']}>
          <CSSTransitionWrapper onEnter={loader} styleVariation="onForeground">
            <AstronomicLoader variation="blurredBackground" logoPosition="top" />
          </CSSTransitionWrapper>
        </div>

        {services !== undefined && services.length < 1 ? (
          <p className="text text--caps">There are no services for the selected categories</p>
        ) : (
          <></>
        )}

        {services ? (
          <Grid
            itemGroup={services?.map((item, i) => {
              return (
                <ServiceCard
                  title={item.name}
                  text={truncateString(item.description, 100)}
                  price={null}
                  link={`/${userRole}/${RoutesEnum.BUILD}/${stringToUserFriendlyURL(item.name)}`}
                  navigateState={{ serviceId: item.id }}
                  key={item.id}
                />
              );
            })}
          />
        ) : (
          <Grid
            itemGroup={[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, i) => (
              <PreloadProductCard key={i} />
            ))}
          />
        )}

        {/* <div className={classNames(styles['container__bottom'])}>
          <div className={classNames(styles['container__bottom__button-group'])}>
            <div className={classNames(styles['container__bottom__button-group__button'])}>
              <MainButton sizeType="medium" visualType="main">
                Load more
              </MainButton>
            </div>

            <div className={classNames(styles['container__bottom__button-group__button'])}>
              <MainButton sizeType="medium" visualType="white">
                Show all
              </MainButton>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BuildFiltersWithServiceCards;
