import { FunctionComponent, useEffect, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import ServiceDetailTemplate from '../../containers/templates/ServiceDetailTemplate/ServiceDetailTemplate';
import callToast from '../../components/blocks/ToastMessage/_parts/callToast/callToast';
import RoutesEnum from '../../enums/routes';
import { normalizeRelatedServices } from '../../utils/normalizeData';

interface GrowDetailViewProps extends IWithUserRoleProps {}

const GrowDetailView: FunctionComponent<GrowDetailViewProps> = ({ user_role: userRole }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState<Record<string, any>>();

  const listId = location.state?.listId;
  const viewAllLink = `/${userRole}/${RoutesEnum.GROW}`;

  const getGrowListDetails = async () => {
    try {
      const response = await api.Content.getGrowListDetails(listId);
      const responseData = response.data;

      if (responseData.success && responseData.data) {
        setData({
          ...responseData.data,
          relatedList: normalizeRelatedServices(responseData.data.relatedList, 'grow', userRole), // Normalize data here
        });
      } else {
        console.error('Error:', responseData.errors);

        callToast({
          title: 'Something went wrong',
          children: `We couldn't retrieve the data. Please try opening the corresponding page again from the services list.`,
          variation: 'error',
        });

        navigate(`/${userRole}/${RoutesEnum.GROW}`);
      }
    } catch (error) {
      console.error('Error: ', error);

      callToast({
        title: 'Something went wrong',
        children: `We couldn't retrieve the data. Please try opening the corresponding page again from the services list.`,
        variation: 'error',
      });

      navigate(`/${userRole}/${RoutesEnum.GROW}`);
    }
  };

  useEffect(() => {
    setData(undefined);
    getGrowListDetails();
  }, [location]);

  return (
    <ServiceDetailTemplate
      userRole={userRole}
      title={data?.listName}
      description={data?.description}
      relatedServices={data?.relatedList}
      loading={data ? false : true}
      viewAll={viewAllLink}
    />
  );
};

export default with_user_role(GrowDetailView);
