import { ENV } from '../../types/application';

export const get_env = (): ENV => {
  const env = process.env.REACT_APP_ENV;

  if (env) {
    if (env === 'development' || env === 'staging' || env === 'production') {
      return env;
    } else {
      throw new Error('REACT_APP_ENV is not correct');
    }
  } else {
    throw new Error('REACT_APP_ENV is not defined');
  }
};

export const get_backend = () => {
  const backend = process.env.REACT_APP_BACKEND;

  if (backend) {
    return backend;
  } else {
    throw new Error('REACT_APP_BACKEND is not defined');
  }
};

export const get_stripe_public_key = () => {
  const stripePublic = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  if (stripePublic) {
    return stripePublic;
  } else {
    throw new Error('REACT_APP_STRIPE_PUBLIC_KEY is not defined');
  }
};
