import { FunctionComponent } from 'react';
import styles from './NotFoundView.module.scss';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../../components/seo/MetaTags/MetaTags';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import Footer from '../../containers/templates/MainTemplate/_parts/footer/Footer';

import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import { ReactComponent as LayersIcon } from '../../asset/images/icons/layers.svg';
import { ReactComponent as BgElements } from '../../asset/images/not_found/bg_elements.svg';
import { ReactComponent as DudeIll } from '../../asset/images/not_found/dude_with_telescope_and_waves.svg';

import { UserRoleEnum } from '../../enums/user';
import Header from '../../containers/templates/MainTemplate/_parts/header/Header';
import { useAppSelector } from '../../redux/hooks';
import classNames from 'classnames';
import BackgroundSectionTemplate from '../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import FooterSection from '../../components/sections/FooterSection/FooterSection';
import RoutesEnum from '../../enums/routes';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import NavigationLink from '../../components/links/NavigationLink/NavigationLink';

interface NotFoundViewProps {}

const NotFoundView: FunctionComponent<NotFoundViewProps> = () => {
  const user_role = UserRoleEnum['founder'].toLocaleLowerCase() as UserRoleEnum;
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const navigate = useNavigate();

  return (
    <div className={styles['general']}>
      <Header user_role={user_role} headerColor="blue" loggedIn={isUserLoggedIn} />

      <MetaTags
        title="Oops, looks like you're lost in space!"
        description="We couldn't find the page you were looking for. But don’t worry, we’re here to help you navigate."
      />

      <section className={classNames(styles['section'])}>
        <BackgroundSectionTemplate color={'blue-dark'}>
          <BgElements className={styles['bg-el']} />
        </BackgroundSectionTemplate>

        <div className={classNames('wrapper')}>
          <div className="content">
            <div className={styles['top-part']}>
              <TitleWithSecTitle
                title={
                  <>
                    Oops, looks like you're <mark>lost in space</mark>!
                  </>
                }
                text={
                  <div className={styles['text-4']}>
                    We couldn't find the page you were looking for. But don’t worry, we’re here to help you navigate.
                  </div>
                }
              ></TitleWithSecTitle>
            </div>

            <div className={styles['middle-part']}>
              <p className={classNames(styles['text-4'], 'text text--h4')}>Here’s what you can do next:</p>

              <div className={styles['block-list']}>
                <div
                  className={classNames(styles['block-list__item'], 'tile tile--color-transparent tile--padding-32px')}
                >
                  <div className={classNames(styles['block-list__item__icon-on-bg'])}>
                    <RocketIcon style={{ fill: 'var(--color-white)' }} />
                  </div>

                  <div
                    className={classNames(styles['block-list__item__icon'])}
                    style={{ backgroundColor: 'rgba(244, 184, 64, 0.10)' }}
                  >
                    <RocketIcon style={{ fill: '#F4B840' }} />
                  </div>

                  <p className={classNames(styles['text-2'], 'text text--h4')}>Go home</p>

                  <p className={classNames(styles['text-3'], 'text text--body-1')}>
                    <NavigationLink path={'/'} colorType="blue">
                      Return to the homepage
                    </NavigationLink>{' '}
                    and start fresh.
                  </p>
                </div>

                <div
                  className={classNames(styles['block-list__item'], 'tile tile--color-transparent tile--padding-32px')}
                >
                  <div className={classNames(styles['block-list__item__icon-on-bg'])}>
                    <LayersIcon style={{ fill: 'var(--color-white)' }} />
                  </div>

                  <div
                    className={classNames(styles['block-list__item__icon'])}
                    style={{ backgroundColor: 'rgba(234, 107, 61, 0.10)' }}
                  >
                    <LayersIcon style={{ fill: '#EA6B3D' }} />
                  </div>

                  <p className={classNames(styles['text-2'], 'text text--h4')}>Explore Our Services</p>

                  <p className={classNames(styles['text-3'], 'text text--body-1')}>
                    Check out what we offer through: <br />
                    <NavigationLink path={`/${user_role}/${RoutesEnum.CONNECT}`} colorType="blue">
                      Connect
                    </NavigationLink>
                    ,{' '}
                    <NavigationLink path={`/${user_role}/${RoutesEnum.BUILD}`} colorType="blue">
                      Build
                    </NavigationLink>
                    ,{' '}
                    <NavigationLink path={`/${user_role}/${RoutesEnum.LEARN}`} colorType="blue">
                      Learn
                    </NavigationLink>
                    ,{' '}
                    <NavigationLink path={`/${user_role}/${RoutesEnum.FUND}`} colorType="blue">
                      Fund
                    </NavigationLink>
                    , or{' '}
                    <NavigationLink path={`/${user_role}/${RoutesEnum.GROW}`} colorType="blue">
                      Grow
                    </NavigationLink>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['dude']}>
          <DudeIll />
        </div>
      </section>

      <SectionDivider color="blue" />

      <FooterSection
        title="No one goes to the moon alone"
        text="Astronomic is your copilot for accelerating every step of your journey to the moon."
        buttonGroup={[
          <JoinForFreeButton />,

          <MainButton
            sizeType={'large'}
            visualType="blank"
            onClick={() => {
              navigate(`/${user_role}/${RoutesEnum.ABOUT}`);
            }}
          >
            About Us
          </MainButton>,
        ]}
      />

      <Footer userRole={user_role} />
    </div>
  );
};

export default NotFoundView;
