import { FunctionComponent, useState } from 'react';
import styles from './TextWithIconForPopUp.module.scss';
import HowRocketFuelWorksPopUp from '../../popups/HowRocketFuelWorksPopUp/HowRocketFuelWorksPopUp';
import classNames from 'classnames';

interface TextWithIconForPopUpProps {
  text?: string | React.ReactElement;
  customIcon?: React.ReactElement;
  className?: string;
}

const TextWithIconForPopUp: FunctionComponent<TextWithIconForPopUpProps> = ({
  text = 'and get $70 in free Rocket Fuel',
  customIcon,
  className,
}) => {
  const [isPopUpActive, setIsPopUpActive] = useState(false);

  const togglePopUp = () => {
    setIsPopUpActive(!isPopUpActive);
  };

  return (
    <div className={classNames(styles['pop-up-text-container'], className)}>
      <p className={classNames(styles['pop-up-text'], 'text text--body-3')} onClick={togglePopUp}>
        <span className={styles['pop-up-text__content']}>{text}</span>

        <span className={styles['pop-up-text__icon']}>
          {customIcon ? (
            customIcon
          ) : (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_9157_175510)">
                <path
                  d="M7.99935 15.3334C3.95602 15.3334 0.666016 12.0434 0.666016 8.00002C0.666016 3.95669 3.95602 0.666687 7.99935 0.666687C12.0427 0.666687 15.3327 3.95669 15.3327 8.00002C15.3327 12.0434 12.0427 15.3334 7.99935 15.3334ZM7.99935 2.00002C4.69068 2.00002 1.99935 4.69135 1.99935 8.00002C1.99935 11.3087 4.69068 14 7.99935 14C11.308 14 13.9993 11.3087 13.9993 8.00002C13.9993 4.69135 11.308 2.00002 7.99935 2.00002ZM8.00602 12H7.99935C7.63135 12 7.33268 11.7014 7.33268 11.3334C7.33268 10.9654 7.63135 10.6667 7.99935 10.6667H8.00602C8.37401 10.6667 8.67268 10.9654 8.67268 11.3334C8.67268 11.7014 8.37401 12 8.00602 12ZM7.94601 9.33335C7.66668 9.33335 7.40668 9.15669 7.31335 8.87735C7.19668 8.52802 7.38535 8.15069 7.73468 8.03402C8.13402 7.89869 9.27868 7.35535 9.27868 6.66669C9.27868 6.35135 9.16801 6.04535 8.96535 5.80469C8.76268 5.56402 8.48068 5.40269 8.17068 5.34935C7.86135 5.29602 7.54068 5.35469 7.26935 5.51402C6.99802 5.67335 6.79135 5.92469 6.68735 6.22135C6.56535 6.56869 6.18468 6.75135 5.83735 6.62869C5.49002 6.50669 5.30735 6.12602 5.43002 5.77869C5.63868 5.18535 6.05202 4.68335 6.59402 4.36469C7.13601 4.04602 7.77668 3.92935 8.39602 4.03535C9.01602 4.14135 9.58001 4.46535 9.98535 4.94602C10.3907 5.42735 10.6127 6.03869 10.612 6.66735C10.612 7.20935 10.3867 8.00669 9.31535 8.72135C8.74735 9.10002 8.18002 9.29135 8.15668 9.29935C8.08668 9.32269 8.01535 9.33402 7.94601 9.33402V9.33335Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_9157_175510">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </span>
      </p>

      <HowRocketFuelWorksPopUp whatIsPopUpActive={isPopUpActive} changeWhatIsPopUpActiveState={togglePopUp} />
    </div>
  );
};

export default TextWithIconForPopUp;
