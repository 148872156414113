import { FunctionComponent } from 'react';
import ContentWithSidebar from '../../containers/templates/ContentWithSidebar/ContentWithSidebar';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface CookiePolicyViewProps {}

const CookiePolicyView: FunctionComponent<CookiePolicyViewProps> = () => {
  return (
    <>
      <MetaTags title="Cookie Policy for Astronomic" />

      <ContentWithSidebar title="Cookie Policy for Astronomic" date="December 1, 2024">
        <p>
          Astronomic Inc. (“Astronomic,” “we,” “our,” or “us”) uses cookies and similar tracking technologies on our
          website to enhance user experience, improve our services, and analyze website traffic. This Cookie Policy
          explains what cookies are, how we use them, and your options for managing them.
        </p>

        <h5>1. What Are Cookies?</h5>
        <p>
          Cookies are small data files stored on your device when you visit a website. They help websites function
          efficiently and provide information to site owners. Cookies can be "session cookies" (deleted after you close
          your browser) or "persistent cookies" (stored on your device until they expire or are deleted).
        </p>

        <h5>2. Types of Cookies We Use</h5>
        <p>We use the following types of cookies on the Astronomic website:</p>
        <ul>
          <li>
            <b>Essential Cookies</b>: These cookies are necessary for the operation of our website and enable core
            functionalities like account login, payment processing, and security measures.
          </li>
          <li>
            <b>Performance Cookies</b>: These cookies collect information about how visitors use our website, helping us
            understand user behavior and improve site functionality.
          </li>
          <li>
            <b>Functionality Cookies</b>: These cookies remember user preferences and settings to enhance your browsing
            experience.
          </li>
          <li>
            <b>Targeting/Advertising Cookies</b>: These cookies track your online activity to deliver personalized
            advertisements based on your interests, both on our site and on third-party platforms.
          </li>
        </ul>

        <h5>3. Why We Use Cookies</h5>
        <p>We use cookies to:</p>
        <ul>
          <li>Ensure the website operates effectively and securely.</li>
          <li>Analyze site usage and improve user experience.</li>
          <li>Remember user preferences, such as language settings.</li>
          <li>Deliver relevant advertisements and marketing content.</li>
        </ul>

        <h5>4. Third-Party Cookies</h5>
        <p>
          We may allow third-party service providers to set cookies on your device to provide analytics, advertisements,
          or other services. These cookies are governed by the third parties' privacy policies, which we encourage you
          to review.
        </p>

        <h5>5. Your Cookie Preferences</h5>
        <p>
          You can manage or delete cookies through your browser settings. Most browsers allow you to block or disable
          cookies, but please note that doing so may limit the functionality of our website. Instructions for managing
          cookies can usually be found in the browser's "Help" menu.
        </p>
        <p>Alternatively, you can opt out of certain third-party advertising cookies using tools like:</p>
        <ul>
          <li>Network Advertising Initiative Opt-Out</li>
          <li>Digital Advertising Alliance Opt-Out</li>
        </ul>

        <h5>6. Updates to This Cookie Policy</h5>
        <p>
          We may update this Cookie Policy to reflect changes in our practices or for other operational, legal, or
          regulatory reasons. Any updates will be posted on this page with an updated "Effective Date."
        </p>

        <h5>7. Contact Us</h5>
        <p>If you have any questions about our use of cookies or this Cookie Policy, please contact us at:</p>
        <ul>
          <li>
            <b>Email</b>: <a href="mailto:privacy@astronomic.com">privacy@astronomic.com</a>
          </li>
          <li>
            <b>Mailing Address</b>: Astronomic Inc., 3300 N Interstate Hwy 35, Austin, TX 78705, USA
          </li>
        </ul>
      </ContentWithSidebar>
    </>
  );
};

export default CookiePolicyView;
