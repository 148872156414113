import { FunctionComponent } from 'react';
import styles from './FlashHighlight.module.scss';

interface FlashHighlightProps {}

const FlashHighlight: FunctionComponent<FlashHighlightProps> = () => {
  return <div className={styles['element']}></div>;
};

export default FlashHighlight;
