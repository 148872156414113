import { FunctionComponent, useEffect } from 'react';
import ConfirmEmailTemplate from '../ConfirmEmailTemplate/ConfirmEmailTemplate';
import api from '../../../../../api';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../../../dto/api';
import { useNavigate } from 'react-router-dom';
import { localStorageGetItem, localStorageSetItem } from '../../../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../../../enums/localStorageKeys';
import RoutesEnum from '../../../../../enums/routes';
import MetaTags from '../../../../../components/seo/MetaTags/MetaTags';

interface SignUpConfirmEmailProps {}

const SignUpConfirmEmail: FunctionComponent<SignUpConfirmEmailProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP) !== '2') {
      navigate(`/${RoutesEnum.SIGN_UP}`);
    }
  }, []);

  const handleOnSubmitApiRequest = async (userId: string, verificationCode: string) => {
    try {
      const response = await api.Auth.verifyAccount({ userId, verificationCode });
      const responseData = response.data as ApiResponseDTO;

      if (responseData.success === true && responseData.data) {
        // localStorage.setItem('userSignUpStep', '3');
        localStorageSetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP, '3');
        navigate(`/${RoutesEnum.SIGN_UP}/${RoutesEnum.CREATE_PASSWORD}`);
        return { success: true, message: 'All good. Proceeding to the next step...' };
      } else {
        console.error('Error:', response.error);
        return { success: false, message: 'An error occurred.' };
      }
    } catch (error) {
      const errorObj = error as AxiosError<ApiResponseDTO>;
      const errorData = errorObj.response?.data;

      if (errorData?.errorMessage) {
        console.error('Error: ', errorData.errorMessage);
        return { success: false, message: errorData.errorMessage };
      }

      console.error('Error: ', error);
      return { success: false, message: 'An error occurred.' };
    }
  };

  const handleOnGetDemoCodeApiRequest = async (userId: string) => {
    try {
      const response = await api.Auth.getUserVerificationCode(userId);
      const verificationCode = response.data.verificationCode;

      if (verificationCode) {
        return { verificationCode: verificationCode };
      }
    } catch (error) {
      console.error('Error: ', error);
      return { verificationCode: '' };
    }

    return { verificationCode: '' };
  };

  const handleRequestVerificationCodeApiRequest = async (userId: string) => {
    try {
      const response = await api.Auth.requestVerificationCode({ userId: localStorage.getItem('userId') });
      const responseData = response.data as ApiResponseDTO;

      if (responseData.success === true && responseData.data) {
        return { success: true };
      }
    } catch (error) {
      console.error('Error: ', error);
      return { success: false };
    }

    return { success: false };
  };

  return (
    <>
      <MetaTags title="Sign-up - Confirm email" />

      <ConfirmEmailTemplate
        onSubmitApiRequest={handleOnSubmitApiRequest}
        onGetDemoCodeApiRequest={handleOnGetDemoCodeApiRequest}
        onRequestVerificationCodeApiRequest={handleRequestVerificationCodeApiRequest}
      />
    </>
  );
};

export default SignUpConfirmEmail;
