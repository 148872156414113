import { ChangeEvent, FunctionComponent, useState } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../../../asset/images/astronomic_logo_3.svg';
import classNames from 'classnames';
import styles from './SignIn.module.scss';
import Input from '../../../../../components/inputs/Input/Input';
import InputPassword from '../../../../../components/inputs/InputPassword/InputPassword';
import { ReactComponent as MailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { useNavigate, useOutletContext } from 'react-router-dom';
import InputMessage from '../../../../../components/texts/InputMessage/InputMessage';
import { hasLowerCase, hasNumber, hasSpecialCharacter, hasUpperCase, isEmailValid } from '../../../../../utils/regex';
import api from '../../../../../api';
import { ApiResponseDTO } from '../../../../../dto/api';
import { AxiosError } from 'axios';
import { useAppDispatch } from '../../../../../redux/hooks';
import { login } from '../../../../../utils/auth/login';
import useFormSubmitLoader from '../../../../../hooks/useFormSubmitLoader';
import RoutesEnum from '../../../../../enums/routes';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';
import MetaTags from '../../../../../components/seo/MetaTags/MetaTags';
import { GENERAL_CONTENT } from '../../../../../data/generalContent';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

interface SignInProps {}

const SignIn: FunctionComponent<SignInProps> = () => {
  const navigate = useNavigate();
  const { isLoading, handleFormSubmitLoader } = useFormSubmitLoader();

  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 1279px)');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError(false);
    setEmailErrorMessage('');
    setErrorMessage('');
    setEmail(e.target.value);
  };

  const handlePasswordOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordError(false);
    setPasswordErrorMessage('');
    setErrorMessage('');
    setPassword(e.target.value);
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let allValid = true;

    setEmailErrorMessage('');
    setPasswordErrorMessage('');
    setSuccessMessage('');
    setEmailError(false);
    setPasswordError(false);
    setErrorMessage('');

    const setEmailErrorState = (message: string) => {
      setEmailErrorMessage(message);
      setEmailError(true);
      allValid = false;
    };

    const setPasswordErrorState = (message: string) => {
      setPasswordErrorMessage(message);
      setPasswordError(true);
      allValid = false;
    };

    if (email === '') {
      setEmailErrorState('Enter your email');
    } else if (isEmailValid(email) === false) {
      setEmailErrorState('Enter valid email');
    }

    if (password === '') {
      setPasswordErrorState('Enter your password');
    } else if (hasLowerCase(password) === false) {
      setPasswordErrorState('One lower case character');
    } else if (hasSpecialCharacter(password) === false) {
      setPasswordErrorState('One special character');
    } else if (hasUpperCase(password) === false) {
      setPasswordErrorState('One uppercase case character');
    } else if (password.length < 8) {
      setPasswordErrorState('8 character minimum');
    } else if (hasNumber(password) === false) {
      setPasswordErrorState('One number');
    }

    await handleFormSubmitLoader(async () => {
      if (allValid) {
        try {
          const response = await api.Auth.login({
            email: email,
            password: password,
          });

          const responseData = response.data as ApiResponseDTO<{ userId: string; refreshToken: string; token: string }>;

          if (responseData.success === true && responseData.data) {
            setSuccessMessage('All good. Proceeding to next step...');
            login(dispatch, responseData.data.token, responseData.data.refreshToken, responseData.data.userId, {
              navigate: navigate,
              navigateUrl: `/${RoutesEnum.ACCOUNT}`,
            });
          } else {
            console.error('Error:', response.error);
          }
        } catch (error) {
          const errorObj = error as AxiosError<ApiResponseDTO>;
          const errorData = errorObj.response?.data;
          setSuccessMessage('');

          if (errorData?.errorMessage) {
            setErrorMessage(errorData.errorMessage);
          }

          console.error('Error: ', error);
        }
      }
    });
  };

  const { togglePopUp } =
    useOutletContext<{
      togglePopUp;
    }>();

  return (
    <div className={styles['container']}>
      <MetaTags title="Sign-in" />

      <div className={styles['container-top-part']}>
        <NavigationLink
          path="/"
          iconLeft={<ArrowIcon style={{ width: '11px', transform: 'rotate(180deg)' }} />}
          withBorder={false}
          colorType="white"
        >
          Back to Homepage
        </NavigationLink>
      </div>

      <div className={styles['content']}>
        <CSSTransitionWrapper onEnter={isLoading} styleVariation="onForeground">
          <AstronomicLoader variation="blurredBackground" color="onBlue" />
        </CSSTransitionWrapper>

        <div className={styles['top-row']}>
          <div className={styles['astronomic-logo']}>{<AstronomicLogo />}</div>
          {isMobile === true && (
            <MainButton sizeType="medium" visualType="white" onClick={togglePopUp}>
              {GENERAL_CONTENT.HOW_ASTRONOMIC_WORKS}
            </MainButton>
          )}
        </div>

        <div className={styles['title']}>
          <h3 className="text text--h3">Sign in</h3>
        </div>

        <form className={classNames(styles['form'], 'input-layout')} onSubmit={handleOnSubmit}>
          <div className="input-layout__row">
            <Input
              value={email}
              type="email"
              onChange={handleEmailOnChange}
              error={emailError}
              size="large"
              placeholder="Enter your email"
              color="gray"
              iconLeft={<MailIcon style={{ width: '22px' }} />}
            />

            {emailErrorMessage && <InputMessage>{emailErrorMessage}</InputMessage>}
          </div>

          <div className="input-layout__row">
            <InputPassword
              value={password}
              size="large"
              color="gray"
              placeholder="••••••••"
              error={passwordError}
              onChange={handlePasswordOnChange}
            />
            {passwordErrorMessage && <InputMessage>{passwordErrorMessage}</InputMessage>}

            <div className={styles['forgot-password']}>
              <NavigationLink path="/forgot-password" colorType="blue">
                Forgot password?
              </NavigationLink>
            </div>
          </div>

          <div className={classNames('input-layout__row input-layout__row--large-margin', styles['row-submit'])}>
            <MainButton sizeType="large" visualType="main" disabled={isLoading}>
              Sign In
            </MainButton>

            {errorMessage && <InputMessage>{errorMessage}</InputMessage>}
            {successMessage && <InputMessage messageState="success">{successMessage}</InputMessage>}
          </div>
        </form>

        <div className={styles['sign-in']}>
          <p className="text text--body-2">Don't have an account?</p>

          <NavigationLink path="/sign-up" sizeType="small" className={styles['sign-in__link']} colorType="blue">
            {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
          </NavigationLink>
        </div>
      </div>

      <div className={styles['container-bottom-part']}></div>
    </div>
  );
};

export default SignIn;
