import RoutesEnum from '../enums/routes';
import { UserRoleEnum } from '../enums/user';
import { stringToUserFriendlyURL } from './stringFormatMethods';

export const normalizeRelatedServices = (
  relatedData: Array<any>,
  type: 'build' | 'learn' | 'fund' | 'grow',
  userRole: UserRoleEnum
): Array<any> => {
  return relatedData?.map((item) => {
    if (type === 'build') {
      return {
        id: item.serviceId,
        name: item.serviceName,
        description: item.serviceDescription,
        link: `/${userRole}/${RoutesEnum.BUILD}/${stringToUserFriendlyURL(item.serviceName)}`,
        navigateState: { serviceId: item.serviceId },
      };
    } else if (type === 'learn') {
      return {
        id: item.mentorId,
        name: item.mentorName,
        description: item.mentorDescription,
        link: `/${userRole}/${RoutesEnum.LEARN}/${stringToUserFriendlyURL(item.mentorName)}`,
        navigateState: { mentorId: item.mentorId },
      };
    } else if (type === 'fund') {
      return {
        id: item.id,
        name: item.mentorName,
        description: item.mentorDescription,
        link: `/${userRole}/${RoutesEnum.FUND}/${stringToUserFriendlyURL(item.mentorName)}`,
        navigateState: { mentorId: item.mentorId },
      };
    } else if (type === 'grow') {
      return {
        id: item.listId,
        name: item.listName,
        description: item.listDescription,
        link: `/${userRole}/${RoutesEnum.GROW}/${stringToUserFriendlyURL(item.listName)}`,
        navigateState: { listId: item.listId },
      };
    }
    return item; // Fallback for unsupported types
  });
};
