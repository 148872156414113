import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './MainButton.module.scss';

export type SizeType = 'small' | 'medium' | 'large';
export type VisualType = 'main' | 'white' | 'blank' | 'blue';

export interface MainButtonProps extends PropsWithChildren {
  onClick?(e?): void;
  className?: string;
  sizeType?: SizeType;
  visualType?: VisualType;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  style?: {};
}

const MainButton: FunctionComponent<MainButtonProps> = ({
  onClick,
  children,
  className,
  sizeType = 'large',
  visualType = 'main',
  iconLeft,
  iconRight,
  disabled,
  style,
  type = undefined,
}) => {
  return (
    <button
      className={classNames(
        styles['main-button'],
        styles[sizeMap[sizeType]],
        styles[visualMap[visualType]],
        styles[`main-button--disabled-${disabled}`],
        className
      )}
      onClick={onClick}
      style={style}
      type={type}
    >
      <div className={styles['main-button__background']}></div>
      <div className={styles['main-button__inner']}>
        {iconLeft && <div className={styles['main-button__inner__icon']}>{iconLeft}</div>}

        <p className={styles['main-button__inner__text']}>{children}</p>

        {iconRight && <div className={styles['main-button__inner__icon']}>{iconRight}</div>}
      </div>
    </button>
  );
};

export const sizeMap: Record<SizeType, string> = {
  small: 'main-button--size-small',
  medium: 'main-button--size-medium',
  large: 'main-button--size-large',
};

export const visualMap: Record<VisualType, string> = {
  main: 'main-button--color-main',
  white: 'main-button--color-white',
  blank: 'main-button--color-blank',
  blue: 'main-button--color-blue',
};

export default MainButton;
