import { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './OnboardingHowAstronomicWorks.module.scss';
import classNames from 'classnames';
import AstronomicLogo from '../../components/brand/AstronomicLogo/AstronomicLogo';
import BackgroundSectionTemplate from '../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import { ReactComponent as Ill_1 } from '../../asset/images/onboarding_how_astronomic_works/ill_1.svg';
import { ReactComponent as Ill_2 } from '../../asset/images/onboarding_how_astronomic_works/ill_2.svg';
import { ReactComponent as Ill_3 } from '../../asset/images/onboarding_how_astronomic_works/ill_3.svg';
import { ReactComponent as Ill_4 } from '../../asset/images/onboarding_how_astronomic_works/ill_4.svg';
import { ReactComponent as Ill_5 } from '../../asset/images/onboarding_how_astronomic_works/ill_5.svg';
import { ReactComponent as Ill_6 } from '../../asset/images/onboarding_how_astronomic_works/ill_6.svg';
import { ReactComponent as InfoIcon } from '../../asset/images/icons/info.svg';
import { ReactComponent as CalculatorIcon } from '../../asset/images/icons/calculator.svg';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../enums/routes';
import NavigationLink from '../../components/links/NavigationLink/NavigationLink';
import { createPortal } from 'react-dom';
import PopUp from '../../components/popups/PopUp/PopUp';
import PricingCalculatorWidget from '../../components/widgets/PricingCalculatorWidget/PricingCalculatorWidget';
import GroupWithGap from '../../components/lists/GroupWithGap/GroupWithGap';
import { UserRoleEnum } from '../../enums/user';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

const TOTAL_STEPS = 2;

const CONTENT = {
  1: {
    mainTitle: 'How Astronomic works',
    items: [
      { title: 'Find products and services on our site', text: 'What you need, when you need it.', ill: <Ill_1 /> },
      { title: 'Speak with a Solutions Specialist', text: 'For estimates and answers.', ill: <Ill_2 /> },
      { title: 'Pay with cards, ACH, or Rocket Fuel', text: 'Earn rewards with every purchase.', ill: <Ill_3 /> },
    ],
  },
  2: {
    mainTitle: 'Rocket Fuel credits',
    items: [
      {
        title: 'Earn $5 in Rocket Fuel for every $100 you spend',
        text: 'For use with any of our products or services.',
        ill: <Ill_4 />,
      },
      { title: 'Save more by prepaying for Rocket Fuel', text: 'One-time or monthly.', ill: <Ill_5 /> },
      {
        title: 'Grant equity for Rocket Fuel multipliers *',
        text: 'Maximize your savings. How does this work?',
        ill: <Ill_6 />,
      },
    ],
  },
};

interface OnboardingHowAstronomicWorksProps {}

const OnboardingHowAstronomicWorks: FunctionComponent<OnboardingHowAstronomicWorksProps> = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [stepOpened, setStepOpened] = useState(new Array(TOTAL_STEPS).fill(false));
  const [equityPopUpActive, setEquityPopUpActive] = useState(false);
  const [calcPopUpActive, setCalcPopUpActive] = useState(false);

  const bottomRef = useRef<HTMLDivElement | null>(null);

  const changeEquityPopUpActiveState = (state) => {
    setEquityPopUpActive(state);
  };

  const changeCalcPopUpActiveState = (state) => {
    setCalcPopUpActive(state);
  };

  useEffect(() => {
    setStep(1);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setStepOpened((prevStepOpened) => {
              const updatedStepOpened = [...prevStepOpened];
              updatedStepOpened[step - 1] = true;
              return updatedStepOpened;
            });
          }
        });
      },
      { threshold: 1.0 } // Trigger when the bottomRef is fully visible
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [step]);

  const handleStepChange = (newStep) => {
    window.scrollTo(0, 0);

    if (TOTAL_STEPS >= newStep) {
      setStep(newStep);
    }
  };

  return (
    <section className={styles['section']}>
      <BackgroundSectionTemplate />

      <MetaTags title="How Astronomic works" />

      <div className={styles['progress-bar']}>
        <div className={styles['progress-bar__current']} style={{ width: `${(step * 100) / TOTAL_STEPS}%` }}></div>
      </div>

      <div className="wrapper type-1">
        <div className={styles['content']}>
          <div className={styles['logo']}>
            <AstronomicLogo color="dark" />
          </div>

          <p className={classNames(styles['title'], 'text text--h1')}>
            {step === 1 && 'How Astronomic works'}
            {step === 2 && 'Rocket Fuel credits'}
          </p>

          {step === 1 && (
            <div className={styles['item-list']}>
              <div className={styles['item']}>
                <div className={styles['line']}></div>

                <div className={styles['item__part-1']}>
                  <div className={styles['number']}>
                    <div className={styles['number__text']}>1</div>
                  </div>

                  <div className={styles['info']}>
                    <p className={classNames(styles['text-1'], 'text text--h3')}>
                      Find products and services on our site
                    </p>

                    <p className={classNames(styles['text-2'], 'text text--body-1')}>
                      What you need, when you need it.
                    </p>
                  </div>
                </div>
                <div className={styles['item__part-2']}>
                  <div className={styles['ill-container']}>
                    <Ill_1 />
                  </div>
                </div>
              </div>

              <div className={styles['item']}>
                <div className={styles['line']}></div>

                <div className={styles['item__part-1']}>
                  <div className={styles['number']}>
                    <div className={styles['number__text']}>2</div>
                  </div>

                  <div className={styles['info']}>
                    <p className={classNames(styles['text-1'], 'text text--h3')}>Speak with a Solutions Specialist</p>

                    <p className={classNames(styles['text-2'], 'text text--body-1')}>For estimates and answers.</p>
                  </div>
                </div>
                <div className={styles['item__part-2']}>
                  <div className={styles['ill-container']}>
                    <Ill_2 />
                  </div>
                </div>
              </div>

              <div className={styles['item']}>
                <div className={styles['line']}></div>

                <div className={styles['item__part-1']}>
                  <div className={styles['number']}>
                    <div className={styles['number__text']}>3</div>
                  </div>

                  <div className={styles['info']}>
                    <p className={classNames(styles['text-1'], 'text text--h3')}>Pay with cards, ACH, or Rocket Fuel</p>

                    <p className={classNames(styles['text-2'], 'text text--body-1')}>
                      Earn rewards with every purchase.
                    </p>
                  </div>
                </div>
                <div className={styles['item__part-2']}>
                  <div className={styles['ill-container']}>
                    <Ill_3 />
                  </div>
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className={styles['item-list']}>
              <div className={styles['item']}>
                <div className={styles['line']}></div>

                <div className={styles['item__part-1']}>
                  <div className={styles['number']}>
                    <div className={styles['number__text']}>1</div>
                  </div>

                  <div className={styles['info']}>
                    <p className={classNames(styles['text-1'], 'text text--h3')}>
                      Earn $5 in Rocket Fuel for every $100 you spend
                    </p>

                    <p className={classNames(styles['text-2'], 'text text--body-1')}>
                      For use with any of our products or services.
                    </p>
                  </div>
                </div>
                <div className={styles['item__part-2']}>
                  <div className={styles['ill-container']}>
                    <Ill_4 />
                  </div>
                </div>
              </div>

              <div className={styles['item']}>
                <div className={styles['line']}></div>

                <div className={styles['item__part-1']}>
                  <div className={styles['number']}>
                    <div className={styles['number__text']}>2</div>
                  </div>

                  <div className={styles['info']}>
                    <p className={classNames(styles['text-1'], 'text text--h3')}>
                      Save more by prepaying for Rocket Fuel
                    </p>

                    <p className={classNames(styles['text-2'], 'text text--body-1')}>One-time or monthly.</p>
                  </div>
                </div>
                <div className={styles['item__part-2']}>
                  <div className={styles['ill-container']}>
                    <Ill_5 />
                  </div>
                </div>
              </div>

              <div className={styles['item']}>
                <div className={styles['line']}></div>

                <div className={styles['item__part-1']}>
                  <div className={styles['number']}>
                    <div className={styles['number__text']}>3</div>
                  </div>

                  <div className={styles['info']}>
                    <p className={classNames(styles['text-1'], 'text text--h3')}>Grant equity for steep discounts</p>

                    <p className={classNames(styles['text-2'], 'text text--body-1')}>
                      Maximize your savings.{' '}
                      <span className={styles['link-like']} onClick={() => changeEquityPopUpActiveState(true)}>
                        How does <wbr /> this work?
                      </span>
                    </p>

                    {/* <div className={styles['button']}>
                      <MainButton visualType="white" onClick={() => changeCalcPopUpActiveState(true)}>
                        Try our Pricing Calculator
                      </MainButton>
                    </div> */}
                  </div>
                </div>
                <div className={styles['item__part-2']}>
                  <div className={styles['ill-container']}>
                    <Ill_6 />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div ref={bottomRef} style={{ height: '1px', width: '100%' }} />

          <footer className={styles['footer']}>
            <div className={styles['flex-container']}>
              <div className={styles['part-1']}>
                <MainButton visualType="white" disabled={step <= 1} onClick={() => handleStepChange(step - 1)}>
                  Back
                </MainButton>
              </div>

              <div className={styles['part-2']}>
                {step !== TOTAL_STEPS && (
                  <MainButton disabled={!stepOpened[step - 1]} onClick={() => handleStepChange(step + 1)}>
                    Continue
                  </MainButton>
                )}

                {step === TOTAL_STEPS && (
                  <MainButton
                    disabled={!stepOpened[TOTAL_STEPS - 1]}
                    onClick={() => navigate(`/${RoutesEnum.COMPLETE_PROFILE}`)}
                  >
                    Get started
                  </MainButton>
                )}
              </div>
            </div>
          </footer>
        </div>
      </div>

      {createPortal(
        <PopUp
          className={styles['equity-pop-up']}
          active={equityPopUpActive}
          onPopUpStateChange={changeEquityPopUpActiveState}
        >
          <div className={styles['equity-pop-up__icon-container']}>
            <InfoIcon style={{ fill: 'var(--brand-orange-600)', width: '32px' }} />
          </div>

          <p className={classNames(styles['equity-pop-up__title'], 'text text--h4')}>How equity grants work</p>

          <p className={classNames(styles['equity-pop-up__text'], 'text text--body-2')}>
            At Astronomic, our mission is to enable as much entrepreneurship as possible. To this end, we’re willing to
            discount our pricing as close to cost as possible if it means enabling our customers’ success.
            <br />
            <br />
            However, we can’t justify the risk of foregoing margin without a tradeoff. With this in mind, if you share
            the reward with us, we’ll share the risk with you. Think of it like “cofounder as a service.”
            <br />
            <br />
            Regardless of how your company is valued, if you grant 1%, 2%, or 3% of your equity to Astronomic, we’ll
            provide steep discounts in pricing across all of our products and services.
            <br />
            <br />
            Refer to our{' '}
            <NavigationLink path={`/${RoutesEnum.TERMS_OF_USE}`} target="_blank">
              Terms of Service
            </NavigationLink>{' '}
            for further clarification. After you register you can schedule a call with one of our Solutions Specialists
            to ask questions or begin the process.
          </p>
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}

      {createPortal(
        <PopUp
          className={styles['calc-pop-up']}
          popUpWidth="960"
          active={calcPopUpActive}
          onPopUpStateChange={changeCalcPopUpActiveState}
          footer={
            <GroupWithGap
              className={styles['calc-pop-up__footer']}
              items={[
                <MainButton
                  visualType="white"
                  sizeType="small"
                  onClick={() => {
                    changeCalcPopUpActiveState(false);
                  }}
                >
                  Close
                </MainButton>,
                <MainButton
                  sizeType="small"
                  onClick={() => {
                    window.open(`/${Object.keys(UserRoleEnum)[0]}/${RoutesEnum.PRICING}`, '_blank');
                  }}
                >
                  Learn more about pricing
                </MainButton>,
              ]}
            />
          }
        >
          <div className={styles['calc-pop-up__icon-container']}>
            <CalculatorIcon style={{ fill: 'var(--brand-orange-600)', width: '24px' }} />
          </div>

          <p className={classNames(styles['calc-pop-up__title'], 'text text--h4')}>Pricing Calculator</p>

          <p className={classNames(styles['calc-pop-up__text'], 'text text--body-2')}>
            Use our pricing calculator to determine how much to pay for our products and services.
          </p>

          <PricingCalculatorWidget className={styles['calc-pop-up__widget']} layoutVar="var-a" />
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </section>
  );
};

export default OnboardingHowAstronomicWorks;
