import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './PricingIllBlocks.module.scss';
import Grid from '../Grid/Grid';
import { ReactComponent as Ill1 } from '../../../asset/images/pricing_ill_1.svg';
import { ReactComponent as Ill2 } from '../../../asset/images/pricing_ill_2.svg';
import { ReactComponent as Ill3 } from '../../../asset/images/pricing_ill_3.svg';

interface PricingIllBlocksProps {
  className?: string;
}

const PricingIllBlocks: FunctionComponent<PricingIllBlocksProps> = ({ className }) => {
  return (
    <div className={classNames(styles['container'])}>
      <Grid
        itemGroup={[
          <div className={styles['item']}>
            <div className={styles['item__ill']}>
              <Ill1 />
            </div>

            <p className={classNames(styles['item__title'], 'text text--h3')}>Cash</p>

            <p className={classNames(styles['item__text'], 'text text--body-1')}>
              Buy products and services with credit, debit, or ACH.
            </p>
          </div>,
          <div className={styles['item']}>
            <div className={styles['item__ill']}>
              <Ill2 />
            </div>

            <p className={classNames(styles['item__title'], 'text text--h3')}>Rocket Fuel</p>

            <p className={classNames(styles['item__text'], 'text text--body-1')}>
              Earn $5 in Rocket Fuel credit for every $100 spent.
            </p>
          </div>,
          <div className={styles['item']}>
            <div className={styles['item__ill']}>
              <Ill3 />
            </div>

            <p className={classNames(styles['item__title'], 'text text--h3')}>Equity</p>

            <p className={classNames(styles['item__text'], 'text text--body-1')}>
              Grant equity to earn discounts on all products and services.
            </p>
          </div>,
        ]}
      />
    </div>
  );
};

export default PricingIllBlocks;
