import { FunctionComponent, useEffect, useState } from 'react';
import { get_env } from '../../../config/functions/app_functions';
import { ENV } from '../../../types/application';

interface ShowOnlyInSomeEnvProps extends React.PropsWithChildren {
  showInWhatEnv?: ENV[];
}

const ShowOnlyInSomeEnv: FunctionComponent<ShowOnlyInSomeEnvProps> = ({
  children,
  showInWhatEnv = ['development'],
}) => {
  const canOutput = showInWhatEnv.some((item) => get_env() === item);

  return <>{canOutput && children}</>;
};

export default ShowOnlyInSomeEnv;
