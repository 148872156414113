import { FunctionComponent, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as AboutIll } from '../../asset/images/about_ill.svg';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import MainButton from '../../components/buttons/MainButton/MainButton';

import { ReactComponent as BubbleIcon } from '../../asset/images/icons/bubble.svg';
import { ReactComponent as RocketIconTwo } from '../../asset/images/icons/rocket_2.svg';
import { ReactComponent as LayersIcon } from '../../asset/images/icons/layers_2.svg';
import { ReactComponent as LightbulbIcon } from '../../asset/images/icons/lightbulb.svg';
import { ReactComponent as PeopleIcon } from '../../asset/images/icons/people.svg';

import { ReactComponent as BgEl } from '../../asset/images/about_us/bg_elements.svg';
import { ReactComponent as Ill_2 } from '../../asset/images/about_ill_2.svg';
import { ReactComponent as Ill_3 } from '../../asset/images/manifesto/slide_2_ill.svg';

import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';

import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import WhatIsAstronomicPopUp from '../../components/popups/WhatIsAstronomicPopUp/WhatIsAstronomicPopUp';
import MetaTags from '../../components/seo/MetaTags/MetaTags';
import classNames from 'classnames';
import styles from './AboutView.module.scss';
import BackgroundSectionTemplate from '../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import GroupWithGap from '../../components/lists/GroupWithGap/GroupWithGap';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';
import NavigationLink from '../../components/links/NavigationLink/NavigationLink';
import useMediaQuery from '../../hooks/useMediaQuery';

interface AboutViewProps extends IWithUserRoleProps {}

const AboutView: FunctionComponent<AboutViewProps> = ({ user_role, router }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  const [isPopUpActive, setIsPopUpActive] = useState(false);

  const togglePopUp = () => {
    setIsPopUpActive(!isPopUpActive);
  };

  return (
    <MainTemplateForMainPages footerSectionVariation="var-1" hideFooterSection={true}>
      <MetaTags
        title="About us"
        description="Building companies is hard. Entrepreneurship can feel like an isolated journey. Finding the right team, mentors, investors, and customers can feel like searching for distant stars in an infinite sky."
      />

      <HeroSection
        secondaryTitle={<mark>About us</mark>}
        title="No one goes to the moon alone"
        text={
          <div style={{ color: 'var(--Text-onDark-Neutral-Secondary)' }}>
            <p>
              <mark>Building companies is hard.</mark> Entrepreneurship can feel like an isolated journey. Finding the
              right team, mentors, investors, and customers can feel like searching for distant stars in an infinite
              sky.
            </p>

            <p>
              Astronomic solves these challenges by being your "cofounder as a service." We're your one-stop shop for
              everything you need in order to launch, build, grow, scale, and exit your company. Point to the stars and
              tell us where we're going, and we'll provide the direction and momentum to get us there.
            </p>
          </div>
        }
        sectionVariation="var-1-c-3"
        illustration={<AboutIll style={{ marginRight: 'auto', marginLeft: 'auto', maxHeight: '512px' }} />}
        markTagColor="blue"
      />

      <WhatIsAstronomicPopUp
        whatIsPopUpActive={isPopUpActive}
        changeWhatIsPopUpActiveState={togglePopUp}
        userRole={user_role}
      />

      <SectionDivider color="blue-dark" />

      <section className={classNames(styles['section-2'])}>
        <BackgroundSectionTemplate color={'blue-dark'}></BackgroundSectionTemplate>

        <div className={classNames('wrapper')}>
          <div className="content">
            <div className={styles['top-part']}>
              <TitleWithSecTitle
                title={'Diversity, Equity, Inclusion, and Belonging'}
                secTitle="DEIB"
                secTitleColor="var(--Text-Brand-Cyan-Default)"
                text={
                  <div className={styles['text-1']}>
                    At Astronomic, we’re building an <mark>open</mark>, <mark>collaborative</mark>, and{' '}
                    <mark>inclusive</mark> culture that values each person’s unique contributions in the collective
                    foundation of our company, community, and customers.
                  </div>
                }
              ></TitleWithSecTitle>
            </div>

            <div className={styles['middle-part']}>
              <div className={styles['middle-part__block-list']}>
                <div className={styles['middle-part__block-list__item']}>
                  <div className={styles['text-blocks-list']}>
                    <div
                      className={classNames(
                        styles['text-blocks-list__item'],
                        'tile tile--color-transparent tile--padding-32px'
                      )}
                    >
                      <p className={classNames(styles['text-2'], 'text text--h4')}>Our Vision</p>
                      <p className={classNames(styles['text-3'], 'text text--body-1')}>
                        To innovate our way into the cosmos
                      </p>
                    </div>

                    <div
                      className={classNames(
                        styles['text-blocks-list__item'],
                        'tile tile--color-transparent tile--padding-32px'
                      )}
                    >
                      <p className={classNames(styles['text-2'], 'text text--h4')}>Our Mission</p>
                      <p className={classNames(styles['text-3'], 'text text--body-1')}>
                        To enable as much entrepreneurship as possible
                      </p>
                    </div>

                    <div
                      className={classNames(
                        styles['text-blocks-list__item'],
                        'tile tile--color-transparent tile--padding-32px'
                      )}
                    >
                      <p className={classNames(styles['text-2'], 'text text--h4')}>Our Purpose</p>
                      <p className={classNames(styles['text-3'], 'text text--body-1')}>
                        To connect the world, using entrepreneurship as a conduit for social change
                      </p>
                    </div>
                  </div>
                </div>

                <div className={styles['middle-part__block-list__item']}>
                  <div className={classNames(styles['ill'], 'tile tile--color-transparent tile--padding-32px')}>
                    <Ill_2 />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['bottom-part']}>
              <GroupWithGap
                className={styles['bottom-part__list']}
                items={[
                  <JoinForFreeButton />,
                  <MainButton visualType="blank" sizeType="large" onClick={togglePopUp}>
                    How Astronomic works
                  </MainButton>,
                ]}
              ></GroupWithGap>
            </div>
          </div>
        </div>
      </section>

      <div className={styles['section-divider']}>
        <div className={styles['section-divider__line']}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1088" height="2" viewBox="0 0 1088 2" fill="none">
            <path opacity="0.25" d="M0 1H1088" stroke="url(#paint0_linear_8969_26283)" />
            <defs>
              <linearGradient
                id="paint0_linear_8969_26283"
                x1="0"
                y1="1.5"
                x2="1088"
                y2="1.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.5" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <section className={classNames(styles['section-3'])}>
        <BackgroundSectionTemplate color={'blue-dark'}></BackgroundSectionTemplate>

        <div className={classNames('wrapper')}>
          <div className="content">
            {isMobile === true && <p className={classNames(styles['title-2'], 'text text--h2')}>Our Values</p>}

            <p className={classNames(styles['title'], 'text text--body-1')}>
              In our orbit, entrepreneurs are “astronomers” who uphold the following values.
            </p>

            <div className={styles['block-list']}>
              {isMobile === false && (
                <div
                  className={classNames(
                    styles['block-list__item'],
                    styles['block-list__item--var-a'],
                    'tile tile--color-transparent tile--padding-32px'
                  )}
                >
                  <p className={classNames(styles['text-1'], 'text text--h3')}>Our Values</p>
                </div>
              )}

              <div
                className={classNames(styles['block-list__item'], 'tile tile--color-transparent tile--padding-32px')}
              >
                <div className={classNames(styles['block-list__item__icon-on-bg'])}>
                  <BubbleIcon style={{ fill: 'var(--color-white)' }} />
                </div>

                <div
                  className={classNames(styles['block-list__item__icon'])}
                  style={{ backgroundColor: 'rgba(128, 208, 184, 0.10)' }}
                >
                  <BubbleIcon style={{ fill: '#80D0B8' }} />
                </div>

                <p className={classNames(styles['text-2'], 'text text--h4')}>Humility</p>

                <p className={classNames(styles['text-3'], 'text text--body-2')}>
                  We’re open to hearing critical feedback.
                </p>
              </div>

              <div
                className={classNames(styles['block-list__item'], 'tile tile--color-transparent tile--padding-32px')}
              >
                <div className={classNames(styles['block-list__item__icon-on-bg'])}>
                  <RocketIconTwo style={{ fill: 'var(--color-white)' }} />
                </div>

                <div
                  className={classNames(styles['block-list__item__icon'])}
                  style={{ backgroundColor: 'rgba(244, 184, 64, 0.10)' }}
                >
                  <RocketIconTwo style={{ fill: '#F4B840' }} />
                </div>

                <p className={classNames(styles['text-2'], 'text text--h4')}>Courage</p>

                <p className={classNames(styles['text-3'], 'text text--body-2')}>
                  We summon the strength to persevere.
                </p>
              </div>

              <div
                className={classNames(styles['block-list__item'], 'tile tile--color-transparent tile--padding-32px')}
              >
                <div className={classNames(styles['block-list__item__icon-on-bg'])}>
                  <LayersIcon style={{ fill: 'var(--color-white)' }} />
                </div>

                <div
                  className={classNames(styles['block-list__item__icon'])}
                  style={{ backgroundColor: 'rgba(234, 107, 61, 0.10)' }}
                >
                  <LayersIcon style={{ fill: '#EA6B3D' }} />
                </div>

                <p className={classNames(styles['text-2'], 'text text--h4')}>Integrity</p>

                <p className={classNames(styles['text-3'], 'text text--body-2')}>To include, to adopt, to be open.</p>
              </div>

              <div
                className={classNames(styles['block-list__item'], 'tile tile--color-transparent tile--padding-32px')}
              >
                <div className={classNames(styles['block-list__item__icon-on-bg'])}>
                  <LightbulbIcon style={{ fill: 'var(--color-white)' }} />
                </div>

                <div
                  className={classNames(styles['block-list__item__icon'])}
                  style={{ backgroundColor: 'rgba(122, 210, 231, 0.10)' }}
                >
                  <LightbulbIcon style={{ fill: '#7AD2E7' }} />
                </div>

                <p className={classNames(styles['text-2'], 'text text--h4')}>Ambition</p>

                <p className={classNames(styles['text-3'], 'text text--body-2')}>
                  We dream big and push hard to get there.
                </p>
              </div>

              <div
                className={classNames(styles['block-list__item'], 'tile tile--color-transparent tile--padding-32px')}
              >
                <div className={classNames(styles['block-list__item__icon-on-bg'])}>
                  <PeopleIcon style={{ fill: 'var(--color-white)' }} />
                </div>

                <div
                  className={classNames(styles['block-list__item__icon'])}
                  style={{ backgroundColor: 'rgba(245, 101, 101, 0.10)' }}
                >
                  <PeopleIcon style={{ fill: '#F56565' }} />
                </div>

                <p className={classNames(styles['text-2'], 'text text--h4')}>Diversity</p>

                <p className={classNames(styles['text-3'], 'text text--body-2')}>
                  We embrace different people and ideas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SectionDivider color="blue-dark" />

      <div className={styles['section-group-1']}>
        <div className={styles['bg-1']}>
          <BgEl />
        </div>

        <section className={classNames(styles['section-4'])}>
          <div className={classNames('wrapper')}>
            <div className="content">
              <TitleWithSecTitle
                title={'Built with 🚀 in Austin, TX'}
                text={
                  <div className={styles['text-1']}>
                    Astronomic was founded in 2020 by{' '}
                    <NavigationLink
                      path="https://www.linkedin.com/in/chrisbeaman/"
                      target="_blank"
                      rel="noreferrer"
                      colorType="blue"
                    >
                      Chris Beaman
                    </NavigationLink>
                    , who previously founded and exited two SaaS companies. <br />
                    <br />
                    After participating in the Techstars accelerator program, Chris was eager to build a tech-enabled
                    “forever accelerator.” With a background in software and a passion for community, Chris set out to
                    build Astronomic and enable anyone with a fire in their belly to start their own company.
                  </div>
                }
              ></TitleWithSecTitle>

              <div className={styles['ill']}>
                <Ill_3 />
              </div>
            </div>
          </div>
        </section>

        <div className={classNames(styles['section-divider'], styles['section-divider--var-a'])}>
          <div className={styles['section-divider__line']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1088" height="2" viewBox="0 0 1088 2" fill="none">
              <path opacity="0.25" d="M0 1H1088" stroke="url(#paint0_linear_8969_26283)" />
              <defs>
                <linearGradient
                  id="paint0_linear_8969_26283"
                  x1="0"
                  y1="1.5"
                  x2="1088"
                  y2="1.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="0.5" stop-color="white" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>

        <section className={classNames(styles['section-5'])}>
          <div className={classNames('wrapper')}>
            <div className="content">
              <TitleWithSecTitle
                title={
                  <>
                    Ready to become an <mark>astronomer</mark>?
                  </>
                }
                text="Join Astronomic today and accelerate your ascent to the moon. "
              ></TitleWithSecTitle>

              <div className={styles['button']}>
                <JoinForFreeButton />
              </div>
            </div>
          </div>
        </section>
      </div>
    </MainTemplateForMainPages>
  );
};

export default with_user_role(AboutView);
