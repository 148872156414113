import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import withAsyncImport from '../containers/hocs/basic/WithAsyncImport';
import ConnectView from '../views/ConnectView/ConnectView';
import BuildView from '../views/BuildView/BuildView';
import BuildDetailView from '../views/BuildDetailView/BuildDetailView';
import CaseStudyView from '../views/CaseStudyView/CaseStudyView';
import LearnView from '../views/LearnView/LearnView';
import FundView from '../views/FundView/FundView';
import GrowView from '../views/GrowView/GrowView';
import PricingView from '../views/PricingView/PricingView';
import PartnerView from '../views/PartnerView/PartnerView';
import AboutView from '../views/AboutView/AboutView';
import SignUpTemplate from '../containers/templates/SignUpTemplate/SignUpTemplate';
import SignUpCreateAccount from '../containers/templates/SignUpTemplate/_parts/SignUpCreateAccount/SignUpCreateAccount';
import SignUpConfirmEmail from '../containers/templates/SignUpTemplate/_parts/SignUpConfirmEmail/SignUpConfirmEmail';
import SignUpCreatePasswordView from '../views/SignUpCreatePasswordView/SignUpCreatePasswordView';
import CreateAccountNameAndRoles from '../views/CreateAccountNameAndRoles/CreateAccountNameAndRoles';
import CompleteProfileTemplate from '../containers/templates/CompleteProfileTemplate/CompleteProfileTemplate';
import SignIn from '../containers/templates/SignUpTemplate/_parts/SignIn/SignIn';
import WelcomeView from '../views/WelcomeView/WelcomeView';
import TermsOfUseView from '../views/TermsOfUseView/TermsOfUseView';
import AccountTemplate from '../containers/templates/AccountTemplate/AccountTemplate';
import AccountDashboard from '../containers/templates/AccountTemplate/_parts/AccountDashboard/AccountDashboard';
import AccountRocketFuel from '../containers/templates/AccountTemplate/_parts/AccountRocketFuel/AccountRocketFuel';
import AccountBoost from '../containers/templates/AccountTemplate/_parts/AccountBoost/AccountBoost';
import AccountSubscription from '../containers/templates/AccountTemplate/_parts/AccountSubscription/AccountSubscription';
import AccountProfile from '../containers/templates/AccountTemplate/_parts/AccountProfile/AccountProfile';
import AccountBilling from '../containers/templates/AccountTemplate/_parts/AccountBilling/AccountBilling';
import AccountNotifications from '../containers/templates/AccountTemplate/_parts/AccountNotifications/AccountNotifications';
import AccountRole from '../containers/templates/AccountTemplate/_parts/AccountRole/AccountRole';
import ForgotPassword from '../containers/templates/SignUpTemplate/_parts/ForgotPassword/ForgotPassword';
import ForgotPasswordNewPasswordView from '../views/ForgotPasswordNewPasswordView/ForgotPasswordNewPasswordView';
import ForgotPasswordPasswordChangedView from '../views/ForgotPasswordPasswordChangedView/ForgotPasswordPasswordChangedView';
import ProtectedRoute from '../containers/wrappers/ProtectedRoute/ProtectedRoute';
import withAuthentication, { AuthProps } from '../containers/hocs/main/withAuthentication';
import RoutesEnum from '../enums/routes';
import ScrollToTop from '../components/helpers/ScrollToTop/ScrollToTop';
import ScheduleMeetingView from '../views/ScheduleMeetingView/ScheduleMeetingView';
import ManifestoView from '../views/ManifestoView/ManifestoView';
import AccountRoleEdit from '../containers/templates/AccountTemplate/_parts/AccountRoleEdit/AccountRoleEdit';
import AccountRoleAdd from '../containers/templates/AccountTemplate/_parts/AccountRoleAdd/AccountRoleAdd';
import AccountRoleAddQuiz from '../containers/templates/AccountTemplate/_parts/AccountRoleAddQuiz/AccountRoleAddQuiz';
import AccountApps from '../containers/templates/AccountTemplate/_parts/AccountApps/AccountApps';
import NotFoundView from '../views/NotFoundView/NotFoundView';
import CompleteProfileView from '../views/CompleteProfileView/CompleteProfileView';
import PrivacyPolicyView from '../views/PrivacyPolicyView/PrivacyPolicyView';
import CookiePolicyView from '../views/CookiePolicyView/CookiePolicyView';
import ConnectDetailView from '../views/ConnectDetailView/ConnectDetailView';
import LearnDetailView from '../views/LearnDetailView/LearnDetailView';
import FundDetailView from '../views/FundDetailView/FundDetailView';
import GrowDetailView from '../views/GrowDetailView/GrowDetailView';
import OnboardingHowAstronomicWorks from '../views/OnboardingHowAstronomicWorks/OnboardingHowAstronomicWorks';
import ManifestoVerTwoView from '../views/ManifestoVerTwoView/ManifestoVerTwoView';

const HomeView = withAsyncImport(() => import('./../views/HomeView/HomeView'));

interface ApplicationRoutesProps extends AuthProps {}

// global redux state
const defaultRole = 'Founder'.toLowerCase();
// global redux state end

const ApplicationRoutes: React.FunctionComponent<ApplicationRoutesProps> = ({ isUserLoggedIn }) => {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path={RoutesEnum.HOME} element={<Navigate replace to={`/${defaultRole}`} />} />

        <Route path="/:role" element={<HomeView />} />
        <Route path={`/:role/${RoutesEnum.CONNECT}`} element={<ConnectView />} />
        <Route path={`/:role/${RoutesEnum.CONNECT}/:connectDetail`} element={<ConnectDetailView />} />

        {/* <Route
          path={`/:role/${RoutesEnum.CONNECT}/${RoutesEnum.CONSTELLATION_DINNERS}`}
          element={<ConstellationDinnersView />}
        />
        <Route
          path={`/:role/${RoutesEnum.CONNECT}/${RoutesEnum.SUPERNOVA_DINNERS}`}
          element={<SupernovaDinnersView />}
        />
        <Route path={`/:role/${RoutesEnum.CONNECT}/${RoutesEnum.GALAXY_DINNERS}`} element={<GalaxyDinnersView />} />
        <Route path={`/:role/${RoutesEnum.CONNECT}/${RoutesEnum.AURORA_DINNERS}`} element={<AuroraDinnersView />} />
        <Route path={`/:role/${RoutesEnum.CONNECT}/${RoutesEnum.SLACK_COMMUNITY}`} element={<SlackCommunityView />} /> */}

        <Route path="/:role/build" element={<BuildView />} />
        {/* <Route path="/:role/build/:product" element={<ServiceDetailView />} /> */}
        <Route path={`/:role/${RoutesEnum.BUILD}/:serviceTitle`} element={<BuildDetailView />} />
        <Route
          path={`/:role/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}/${RoutesEnum.CASE_STUDY}`}
          element={<CaseStudyView />}
        />

        <Route path={`/:role/${RoutesEnum.LEARN}`} element={<LearnView />} />
        <Route path={`/:role/${RoutesEnum.LEARN}/:serviceTitle`} element={<LearnDetailView />} />

        <Route path={`/:role/${RoutesEnum.FUND}`} element={<FundView />} />
        <Route path={`/:role/${RoutesEnum.FUND}/:serviceTitle`} element={<FundDetailView />} />

        <Route path={`/:role/${RoutesEnum.GROW}`} element={<GrowView />} />
        <Route path={`/:role/${RoutesEnum.GROW}/:serviceTitle`} element={<GrowDetailView />} />

        <Route path={`/:role/${RoutesEnum.PRICING}`} element={<PricingView />} />
        <Route path="/:role/partner" element={<PartnerView />} />
        <Route path="/:role/about" element={<AboutView />} />

        <Route path={`/${RoutesEnum.TERMS_OF_USE}`} element={<TermsOfUseView />} />
        <Route path={`/${RoutesEnum.PRIVACY_POLICY}`} element={<PrivacyPolicyView />} />
        <Route path={`/${RoutesEnum.COOKIE_POLICY}`} element={<CookiePolicyView />} />

        <Route
          path={`/:role/${RoutesEnum.SCHEDULE_MEETING}`}
          element={
            <ProtectedRoute redirectPath={`/${RoutesEnum.SIGN_IN}`} isAllowed={isUserLoggedIn}>
              <ScheduleMeetingView />
            </ProtectedRoute>
          }
        />

        <Route
          element={
            <ProtectedRoute redirectPath={`/${RoutesEnum.ACCOUNT}`} isAllowed={!isUserLoggedIn}>
              <SignUpTemplate />
            </ProtectedRoute>
          }
        >
          <Route path={RoutesEnum.SIGN_IN} element={<SignIn />} />

          <Route path={RoutesEnum.SIGN_UP}>
            <Route index element={<SignUpCreateAccount />} />
            <Route path="confirm-email" element={<SignUpConfirmEmail />} />
          </Route>

          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route path="/sign-up/create-password" element={<SignUpCreatePasswordView />} />
        <Route path="/sign-up/name-and-roles" element={<CreateAccountNameAndRoles />} />
        <Route
          path={`/${RoutesEnum.SIGN_UP}/${RoutesEnum.HOW_ASTRONOMIC_WORKS}`}
          element={<OnboardingHowAstronomicWorks />}
        />

        <Route path="/reset-password" element={<ForgotPasswordNewPasswordView />} />
        <Route path="/password-changed" element={<ForgotPasswordPasswordChangedView />} />

        <Route path={`/${RoutesEnum.COMPLETE_PROFILE}`} element={<CompleteProfileView />} />

        <Route path={`/${RoutesEnum.MANIFESTO}`} element={<ManifestoVerTwoView />} />

        <Route path="/welcome" element={<WelcomeView />} />

        <Route
          path={RoutesEnum.ACCOUNT}
          element={
            <ProtectedRoute redirectPath={`/${RoutesEnum.SIGN_IN}`} isAllowed={isUserLoggedIn}>
              <AccountTemplate />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="dashboard" replace />} />

          <Route path="dashboard" element={<AccountDashboard />} />

          <Route path="rocket-fuel" element={<AccountRocketFuel />} />
          <Route path="rocket-fuel/boost" element={<AccountBoost />} />

          {/* <Route path="subscription" element={<AccountSubscription />} /> */}
          <Route path="apps" element={<AccountApps />} />

          <Route path="profile" element={<AccountProfile />} />
          <Route path="profile/role" element={<AccountRole />} />
          <Route path="profile/role/edit" element={<AccountRoleEdit />} />
          <Route path="profile/role/add" element={<AccountRoleAdd />} />
          {/* <Route path="profile/role/add/quiz" element={<AccountRoleAddQuiz />} /> */}

          <Route path={RoutesEnum.BILLING} element={<AccountBilling />} />

          <Route path="notifications" element={<AccountNotifications />} />
        </Route>

        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default withAuthentication(ApplicationRoutes);
