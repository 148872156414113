import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import RadioList, { RadioData } from '../../inputs/RadioList/RadioList';
import MainButton from '../../buttons/MainButton/MainButton';
import Input from '../../inputs/Input/Input';
import { ReactComponent as Chevron } from '../../../asset/images/chevron.svg';
import { ReactComponent as LensIcon } from '../../../asset/images/icons/lens.svg';
import { UserRoleEnum } from '../../../enums/user';
import CheckboxList, { CheckboxData } from '../../inputs/CheckboxList/CheckboxList';
import styles from './GrowFiltersWithServiceCards.module.scss';
import api from '../../../api';
import { getRoleIdByUserRole } from '../../../utils/role';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../dto/api';
import ServiceCard from '../ServiceCard/ServiceCard';
import PreloadProductCard from '../PreloadProductCard/PreloadProductCard';
import { stringToUserFriendlyURL, truncateString } from '../../../utils/stringFormatMethods';
import RoutesEnum from '../../../enums/routes';
import Grid from '../Grid/Grid';

const testRadioName = 'name1';

const testCheckboxData_1: CheckboxData[] = [
  { label: 'United States', value: '1' },
  { label: `California`, value: '2' },
  { label: `Texas`, value: '3' },
  { label: `San Francisco`, value: '4' },
  { label: `China`, value: '5' },
  { label: `London`, value: '6' },
];

const testRadioData_2: CheckboxData[] = [
  { label: 'All time', value: '15' },
  { label: 'March 2023', value: '16' },
  { label: 'February 2023', value: '17' },
  { label: 'January 2023', value: '18' },
  { label: 'All of 2023', value: '19' },
  { label: 'All of 2022', value: '110' },
  { label: 'All of 2021', value: '111' },
  { label: 'All of 2020', value: '112' },
];

export interface GrowFiltersWithServiceCardsProps {
  userRole: UserRoleEnum;
}

const GrowFiltersWithServiceCards: FunctionComponent<GrowFiltersWithServiceCardsProps> = ({ userRole }) => {
  // old filters
  // const [checkboxFilterData, setCheckboxFilterData] = useState<string[]>([]);
  // const [radioFilterData, setRadioFilterData] = useState<string>(testCheckboxData_1[0].value);
  // old filters end

  const [services, setServices] = useState<Array<any>>();
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  const getServices = async () => {
    setLoading(true);
    try {
      const response = await api.Content.getGrowListByRole(getRoleIdByUserRole(userRole));
      const responseData = response.data;

      if (responseData.success === true && responseData.data) {
        setServices(responseData.data);
        console.log(responseData.data);
      } else {
        console.error('Error:', responseData.errors);
      }
    } catch (error) {
      const errorObj = error as AxiosError<ApiResponseDTO>;
      const errorData = errorObj.response?.data;
      if (errorData?.errorMessage) {
        console.error(errorData.errorMessage);
      }
      console.error('Error: ', error);
    } finally {
      setLoading(false);
    }
  };

  // const filteredServices = services?.filter((service) => {
  //   const normalizedQuery = searchQuery.toLowerCase().trim();
  //   const title = service.listName.toLowerCase();
  //   const description = service.description.toLowerCase();

  //   return title.includes(normalizedQuery) || description.includes(normalizedQuery);
  // });

  const normalize = (str: string) => str.toLowerCase().replace(/[^a-z0-9\s]/g, '');

  const levenshteinDistance = (a: string, b: string): number => {
    const matrix = Array.from({ length: a.length + 1 }, () => Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
      }
    }

    return matrix[a.length][b.length];
  };

  const typoTolerance = 2; // Allow up to 2 typos

  const filteredServices = services?.filter((service) => {
    const normalizedQuery = normalize(searchQuery);
    const title = normalize(service.listName);
    const description = normalize(service.description);

    const matches = (content: string) =>
      content.includes(normalizedQuery) || levenshteinDistance(content, normalizedQuery) <= typoTolerance;

    return matches(title) || matches(description);
  });

  useEffect(() => {
    getServices();
  }, []);

  return (
    <div className={classNames(styles['container'])}>
      <div className={classNames(styles['container__filters'])}>
        {/* old filters jsx */}

        {/* <div className={classNames(styles['container__filters__item'])}>
          <div className={classNames(styles['container__filters__item__title'])}>
            <h5 className="text text--h5">Location</h5>
          </div>

          <div className={classNames(styles['container__filters__item__search-input'])}>
            <Input placeholder="Search location" iconLeft={<LensIcon style={{ width: '20px' }} />} />
          </div>

          <div className={classNames(styles['container__filters__item__input-group'])}>
            <CheckboxList data={testCheckboxData_1} onChange={setCheckboxFilterData} name="test" />
          </div>

          <div className={classNames(styles['container__filters__item__expand-button'])} onClick={() => {}}>
            <div className={styles['container__filters__item__expand-button__text']}>Show all locations</div>

            <div className={styles['container__filters__item__expand-button__icon']}>
              <Chevron />
            </div>
          </div>
        </div>

        <div className={classNames(styles['container__filters__item'])}>
          <div className={classNames(styles['container__filters__item__title'])}>
            <h5 className="text text--h5">Disciplines</h5>
          </div>

          <div className={classNames(styles['container__filters__item__input-group'])}>
            <RadioList
              name={testRadioName}
              data={testRadioData_2}
              onChange={setRadioFilterData}
              checkedValue={testCheckboxData_1[0].value}
            />
          </div>
        </div> */}

        {/* old filter jsx end */}

        <div className={classNames(styles['container__filters__item'])}>
          <div className={classNames(styles['container__filters__item__title'])}>
            <h5 className="text text--h5">Search</h5>
          </div>

          <div className={classNames(styles['container__filters__item__search-input'])}>
            <Input
              placeholder="Enter keyword"
              iconLeft={<LensIcon style={{ width: '20px' }} />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles['container__cards'])}>
        {loading ? (
          <Grid
            itemGroup={[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, i) => (
              <PreloadProductCard key={i} />
            ))}
          />
        ) : filteredServices && filteredServices.length > 0 ? (
          <Grid
            itemGroup={filteredServices.map((item) => (
              <ServiceCard
                title={item.listName}
                text={truncateString(item.description, 100)}
                price={null}
                link={`/${userRole}/${RoutesEnum.GROW}/${stringToUserFriendlyURL(item.listName)}`}
                key={item.id}
                navigateState={{ listId: item.id }}
              />
            ))}
          />
        ) : (
          <p className="text text--body-1">No matching results. Please try a different search term</p>
        )}

        {/* bottom buttons */}
        {/* <div className={classNames(styles['container__bottom'])}>
          <div className={classNames(styles['container__bottom__button-group'])}>
            <div className={classNames(styles['container__bottom__button-group__button'])}>
              <MainButton sizeType="medium" visualType="main">
                Load more
              </MainButton>
            </div>

            <div className={classNames(styles['container__bottom__button-group__button'])}>
              <MainButton sizeType="medium" visualType="white">
                Show all
              </MainButton>
            </div>
          </div>
        </div> */}
        {/* bottom buttons end*/}
      </div>
    </div>
  );
};

export default GrowFiltersWithServiceCards;
