import { FunctionComponent } from 'react';
import ContentWithSidebar from '../../containers/templates/ContentWithSidebar/ContentWithSidebar';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface PrivacyPolicyViewProps {}

const PrivacyPolicyView: FunctionComponent<PrivacyPolicyViewProps> = () => {
  return (
    <>
      <MetaTags title="Privacy Policy for Astronomic" />

      <ContentWithSidebar title="Privacy Policy for Astronomic" date="December 1, 2024">
        <p>
          Astronomic Inc. (“Astronomic,” “we,” “our,” or “us”) respects your privacy and is committed to protecting your
          personal information. This Privacy Policy describes how we collect, use, share, and protect information when
          you access our services, which include our website, events, software, membership programs, and professional
          consulting services.
        </p>
        <p>
          By using our services, you consent to the practices described in this Privacy Policy. If you have any
          questions, please contact us at <a href="mailto:privacy@astronomic.com">privacy@astronomic.com</a>.
        </p>

        <h5>1. Information We Collect</h5>
        <p>
          Subsidiary-Specific Information: Additional data is collected based on the specific services, such as dietary
          preferences and travel needs for events, or membership attendance and networking preferences for Galaxy
          members.
        </p>

        <p>
          User Accounts: We collect personal information when users create accounts on astronomic.com, including
          information necessary for accessing Rocket Fuel balances, referral rewards, and community invitations.
        </p>
        <p>
          Rocket Fuel and Referral Data: Information on Rocket Fuel credits, prepayment subscriptions, equity-based
          rewards, and referral credits is stored in customer profiles and visible in the user dashboard.
        </p>
        <p>We collect various types of information to provide and improve our services, including:</p>
        <ul>
          <li>
            <b>Personal Information</b>: Information you provide during account creation, event registration, or
            membership enrollment, such as name, email address, phone number, mailing address, job title, and company
            information.
          </li>
          <li>
            <b>Financial Information</b>: For payment processing, we collect billing information such as credit card
            numbers or other payment details. We do not store this information; payments are processed by secure
            third-party payment providers.
          </li>
          <li>
            <b>Technical Information</b>: When you access our website or software, we may collect data about your
            device, IP address, browser type, pages visited, and other information that helps us analyze user behavior
            and improve service functionality.
          </li>
          <li>
            <b>Event-Specific Information</b>: When you register for events, we may collect additional information, such
            as dietary preferences, travel needs, and guest lists. This helps us customize and improve the event
            experience.
          </li>
          <li>
            <b>Member-Provided Information</b>: For members of Galaxy or other Astronomic programs, we collect
            information regarding your event attendance, networking preferences, and investment or partnership
            interests.
          </li>
        </ul>

        <h5>2. How We Use Your Information</h5>
        <p>We use the information we collect to:</p>
        <ul>
          <li>
            <b>Provide and Manage Services</b>: To deliver, personalize, and maintain our services, including event
            facilitation, membership programs, and consulting services.
          </li>
          <li>
            <b>Improve Service Quality</b>: To enhance our software, event, and user experiences, including analyzing
            usage trends and interactions.
          </li>
          <li>
            <b>Communicate with You</b>: To send important updates, service confirmations, reminders, and support
            messages, as well as promotional information, where permitted by law.
          </li>
          <li>
            <b>Facilitate Payments</b>: To securely process payments for memberships, events, and other services through
            our payment providers.
          </li>
          <li>
            <b>Support Compliance</b>: To meet legal, regulatory, and security obligations, and to protect against
            unauthorized access or fraudulent activity.
          </li>
        </ul>

        <h5>3. Information Sharing and Disclosure</h5>
        <p>
          Subsidiary-Specific Sharing: We may share information with vendors specific to each subsidiary's needs, such
          as event coordinators for dinners and data brokers for Astronomic Grow.
        </p>
        <p>
          We do not sell or rent your personal information to third parties. We may share information in the following
          circumstances:
        </p>
        <ul>
          <li>
            <b>With Service Providers</b>: We work with trusted vendors who perform essential functions on our behalf,
            such as payment processing, event coordination, and data hosting. These parties are contractually obligated
            to keep your information secure and only use it for specified purposes.
          </li>
          <li>
            <b>For Legal Compliance and Safety</b>: We may disclose information to comply with legal obligations,
            enforce our terms of service, protect Astronomic's rights and property, or ensure the safety of our users.
          </li>
          <li>
            <b>With Your Consent</b>: In specific cases, such as with co-sponsored events or collaborative offers, we
            may request your permission to share certain information with selected partners.
          </li>
        </ul>

        <h5>4. Cookies and Tracking Technologies</h5>
        <p>
          Our website and software may use cookies, web beacons, and similar technologies to collect data about your
          interactions with our services. These tools help us:
        </p>
        <ul>
          <li>Understand usage patterns and tailor content accordingly.</li>
          <li>Improve website performance and enhance user experience.</li>
          <li>Offer relevant advertisements on our website and third-party platforms.</li>
        </ul>
        <p>
          You can manage your cookie preferences in your browser settings; however, disabling cookies may limit some
          features of our services.
        </p>

        <h5>5. Data Security</h5>
        <p>
          We implement reasonable security measures to protect your personal information, including encryption, access
          control, and secure data storage. However, no data transmission over the Internet or method of electronic
          storage is fully secure, and we cannot guarantee absolute security.
        </p>

        <h5>6. Data Retention</h5>
        <p>
          We retain personal information only for as long as necessary to fulfill the purposes for which it was
          collected, including satisfying legal, regulatory, or accounting requirements. When your information is no
          longer needed, we will securely delete or anonymize it.
        </p>

        <h5>7. Your Rights and Choices</h5>
        <p>
          Account Deletion and Data Requests: Users may delete their accounts or request full data deletion, which will
          also delete their account and forfeit any Rocket Fuel credits. Rocket Fuel credits are non-cashable and
          non-transferable, and will expire if unused after 12 months.
        </p>
        <p>Depending on your location, you may have certain rights regarding your personal information, such as:</p>
        <ul>
          <li>
            <b>Access</b>: You may request to access the personal information we hold about you.
          </li>
          <li>
            <b>Correction</b>: You may request corrections to any inaccurate or incomplete information.
          </li>
          <li>
            <b>Deletion</b>: You may request deletion of your information, subject to certain limitations.
          </li>
          <li>
            <b>Opt-Out</b>: You may unsubscribe from marketing communications at any time by following the opt-out
            instructions in our emails or contacting us.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at{' '}
          <a href="mailto:privacy@astronomic.com">privacy@astronomic.com</a>. We may verify your identity before
          processing certain requests.
        </p>

        <h5>8. International Data Transfers</h5>
        <p>
          If you are accessing our services from outside the United States, please be aware that your information may be
          transferred to, stored, and processed in the U.S. or other countries where we operate. By using our services,
          you consent to such transfers, which are subject to safeguards in line with applicable privacy laws.
        </p>

        <h5>9. Privacy of Children</h5>
        <p>
          Our services are intended for individuals 18 years and older. We do not knowingly collect personal information
          from children under 18. If we become aware of any such data, we will promptly delete it.
        </p>

        <h5>10. Changes to This Privacy Policy</h5>
        <p>
          Astronomic may update this Privacy Policy from time to time to reflect changes in our practices or legal
          obligations. We will notify you of any significant changes via our website or direct communication. Please
          review this policy periodically to stay informed of how we protect your information.
        </p>

        <h5>11. Contact Us</h5>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:</p>
        <ul>
          <li>
            <b>Email</b>: <a href="mailto:privacy@astronomic.com">privacy@astronomic.com</a>
          </li>
          <li>
            <b>Mailing Address</b>: Astronomic Inc, 3300 N Interstate Hwy 35, Austin, TX 78705, USA
          </li>
        </ul>
      </ContentWithSidebar>
    </>
  );
};

export default PrivacyPolicyView;
