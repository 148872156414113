import { FunctionComponent, useEffect, useState } from 'react';
import api from '../../api';
import { useLocation, useNavigate } from 'react-router-dom';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import HeroSectionSkeleton from '../../components/sections/HeroSection/HeroSectionSkeleton';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import styles from './ConnectDetailView.module.scss';
import { ConnectProgramEnum } from '../../enums/connect';
import { ReactComponent as ExternalLinkIcon } from '../../asset/images/icons/external_link.svg';
import { ReactComponent as ContDinnersIll } from '../../asset/images/connect_ill_1.svg';
import { ReactComponent as SupernovaDinnersIll } from '../../asset/images/connect_ill_2.svg';
import { ReactComponent as GalaxyDinnersIll } from '../../asset/images/connect_ill_3.svg';
import { ReactComponent as AuroraDinnersIll } from '../../asset/images/connect_ill_4.svg';
import { ReactComponent as SlackIll } from '../../asset/images/connect_ill_5.svg';
import { ReactComponent as CalendarIcon } from '../../asset/images/icons/calendar_2.svg';
import { ReactComponent as ClipboardListIcon } from '../../asset/images/icons/clipboard_list.svg';
import { ReactComponent as CrosshairIcon } from '../../asset/images/icons/crosshair.svg';
import { ReactComponent as BubbleIcon } from '../../asset/images/icons/bubble.svg';
import { ReactComponent as DashboardIcon } from '../../asset/images/icons/dashboard.svg';
import { ReactComponent as LightbulbIcon } from '../../asset/images/icons/lightbulb.svg';
import { ReactComponent as ReceiptIcon } from '../../asset/images/icons/receipt.svg';
import { ReactComponent as WalletIcon } from '../../asset/images/icons/wallet.svg';
import { ReactComponent as SmartphoneIcon } from '../../asset/images/icons/smartphone.svg';
import { ReactComponent as CircleMinusIcon } from '../../asset/images/icons/circle_minus.svg';
import { ReactComponent as StarIcon } from '../../asset/images/icons/star_2.svg';
import { ReactComponent as PinIcon } from '../../asset/images/icons/pin_2.svg';
import { ReactComponent as PersonIcon } from '../../asset/images/icons/person_2.svg';
import { ReactComponent as Calendar2Icon } from '../../asset/images/icons/calendar_3.svg';
import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';
import { ReactComponent as PresentationIcon } from '../../asset/images/icons/presentation.svg';
import { ReactComponent as CircleTickIcon } from '../../asset/images/icons/circle_tick.svg';
import { ReactComponent as ChatIcon } from '../../asset/images/icons/chat.svg';
import { ReactComponent as ContactIcon } from '../../asset/images/icons/contact.svg';
import { ReactComponent as PersonCheckIcon } from '../../asset/images/icons/person_check_2.svg';
import { ReactComponent as ChecklistIcon } from '../../asset/images/icons/checklist.svg';
import { ReactComponent as CalendarTickIcon } from '../../asset/images/icons/calendar_tick.svg';
import { ReactComponent as ChefIcon } from '../../asset/images/icons/chef.svg';
import { ReactComponent as TrendUpIcon } from '../../asset/images/icons/trend_up.svg';
import { ReactComponent as SlackIcon } from '../../asset/images/icons/slack_2.svg';
import { ReactComponent as AccountIcon } from '../../asset/images/icons/account.svg';
import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { GENERAL_CONTENT } from '../../data/generalContent';
import WhatIsAstronomicPopUp from '../../components/popups/WhatIsAstronomicPopUp/WhatIsAstronomicPopUp';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';
import StandardAndRocketFuelPricing from '../../components/blocks/StandardAndRocketFuelPricing/StandardAndRocketFuelPricing';
import { getCollectionOfStringDividedByWord } from '../../utils/stringFormatMethods';
import classNames from 'classnames';
import NavigationLink from '../../components/links/NavigationLink/NavigationLink';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import Grid from '../../components/blocks/Grid/Grid';
import RoutesEnum from '../../enums/routes';
import callToast from '../../components/blocks/ToastMessage/_parts/callToast/callToast';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface ConnectDetailViewProps extends IWithUserRoleProps {}

const programTitleToBrandNameMapping: { [key: string]: string } = {
  'Founder Dinners': ConnectProgramEnum.CONSTELLATION_DINNERS,
  'Business Owner Dinners': ConnectProgramEnum.CONSTELLATION_DINNERS,
  'Service Provider Dinners': ConnectProgramEnum.CONSTELLATION_DINNERS,
  'Fundraising Dinners': ConnectProgramEnum.SUPERNOVA_DINNERS,
  'Biz Dev Dinners': ConnectProgramEnum.AURORA_DINNERS,
  'Speaker Series': ConnectProgramEnum.GALAXY_DINNERS,
  'Online Community': ConnectProgramEnum.SLACK,
  'Advice Dinners': ConnectProgramEnum.NEBULA_DINNERS,
};

const programsContent: Record<ConnectProgramEnum, any> = {
  [ConnectProgramEnum.CONSTELLATION_DINNERS]: {
    ill: <ContDinnersIll />,
    howItWorksSectionIll: [
      <CalendarIcon />,
      <ClipboardListIcon />,
      <CrosshairIcon />,
      <BubbleIcon style={{ fill: 'var(--color-main)' }} />,
    ],
    logisticsIll: [
      <DashboardIcon />,
      <LightbulbIcon />,
      <ReceiptIcon />,
      <WalletIcon />,
      <SmartphoneIcon />,
      <CircleMinusIcon />,
    ],
    brandLink: 'https://constellationdinners.com/',
  },
  [ConnectProgramEnum.SUPERNOVA_DINNERS]: {
    ill: <SupernovaDinnersIll />,
    howItWorksSectionIll: [<CrosshairIcon />, <StarIcon />, <PinIcon />, <PersonIcon />],
    logisticsIll: [
      <Calendar2Icon />,
      <TimeIcon style={{ fill: 'var(--color-main)' }} />,
      <PresentationIcon />,
      <CircleTickIcon />,
      <ChatIcon />,
      <ContactIcon />,
    ],
    brandLink: 'https://supernovadinners.com/',
  },

  [ConnectProgramEnum.GALAXY_DINNERS]: {
    ill: <GalaxyDinnersIll />,
    howItWorksSectionIll: [<CalendarTickIcon />, <StarIcon />, <ChefIcon />, <TrendUpIcon />],
    logisticsIll: [],
    brandLink: 'https://galaxydinners.com/',
  },

  [ConnectProgramEnum.AURORA_DINNERS]: {
    ill: <AuroraDinnersIll />,
    howItWorksSectionIll: [<CrosshairIcon />, <StarIcon />, <PinIcon />, <PersonCheckIcon />],
    logisticsIll: [
      <Calendar2Icon />,
      <TimeIcon style={{ fill: 'var(--color-main)' }} />,
      <ChecklistIcon />,
      <CircleTickIcon />,
      <ChatIcon />,
      <ContactIcon />,
    ],
    brandLink: 'https://auroradinners.com/',
  },

  [ConnectProgramEnum.SLACK]: {
    ill: <SlackIll />,
    howItWorksSectionIll: [
      <SlackIcon />,
      <AccountIcon style={{ fill: 'var(--color-main)' }} />,
      <CircleMinusIcon />,
      <RocketIcon style={{ fill: 'var(--color-main)', width: '24px' }} />,
    ],
    logisticsIll: [],
    brandLink: '',
  },

  [ConnectProgramEnum.NEBULA_DINNERS]: {
    ill: null,
    howItWorksSectionIll: [],
    logisticsIll: [],
    brandLink: '',
  },
};

const ConnectDetailView: FunctionComponent<ConnectDetailViewProps> = ({ user_role: userRole }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState<Record<string, any>>();
  const [isPopUpActive, setIsPopUpActive] = useState(false);

  const programId = location.state?.programId;

  const togglePopUp = () => {
    setIsPopUpActive(!isPopUpActive);
  };

  const getConnectPrograms = async () => {
    try {
      const response = await api.Content.getConnectProgramInfo(programId ? programId : '');
      const responseData = response.data;

      if (responseData.success && responseData.data) {
        setContent(responseData.data);
      } else {
        console.error('Error:', responseData.errors);
      }
    } catch (error) {
      console.error('Error: ', error);

      callToast({
        title: 'Something went wrong',
        children: `We couldn't retrieve the data. Please try opening the corresponding page again from the services list.`,
        variation: 'error',
      });

      navigate(`/${userRole}/${RoutesEnum.CONNECT}`);
    }
  };

  useEffect(() => {
    getConnectPrograms();
  }, [location]);

  const programAdditionalContent = programsContent[content?.brandName];

  const standardPricing = getCollectionOfStringDividedByWord('/', content?.standardPrice);
  const rocketFuelPricing = getCollectionOfStringDividedByWord('/', content?.rocketFuelPrice);

  const pathnameWithoutRoleArr = location.pathname.split('/').filter((item, i) => i !== 0 && i !== 1);
  const programName = pathnameWithoutRoleArr[pathnameWithoutRoleArr.length - 1].replace(/-/g, ' ').replace(/_/g, ' ');

  console.log('content', content);
  console.log('programAdditionalContent', programAdditionalContent);
  console.log('programAdditionalContent?.brandLink', programAdditionalContent?.brandLink);

  return (
    <MainTemplate color="white">
      <MetaTags title={content?.heroHeadline} description={content?.heroDescription} />

      {content ? (
        <>
          <HeroSection
            title={content.heroHeadline}
            text={content.heroDescription}
            sectionVariation="var-1-a"
            illustration={programAdditionalContent?.ill ? programAdditionalContent?.ill : <ContDinnersIll />}
            sectionBackgroundColor="Background-Neutral-Primary"
            buttonGroup={[
              <JoinForFreeButton />,
              <MainButton sizeType={'large'} visualType="white" onClick={togglePopUp}>
                {GENERAL_CONTENT.HOW_ASTRONOMIC_WORKS}
              </MainButton>,
            ]}
            breadcrumbs={<Breadcrumbs userRole={userRole} />}
            elementAfterText={
              content?.standardPrice === 'Free' ? (
                <div className={styles['hero-price-container']}>
                  <p className={classNames(styles['hero-price__text-1'], 'text text--caps-small')}>Standard Pricing</p>
                  <p className={classNames(styles['hero-price__text-2'], 'text text--h3')}>Free</p>
                </div>
              ) : (
                <div className={styles['hero-price-container']}>
                  <StandardAndRocketFuelPricing
                    sizes="var-a"
                    hideRocketFuel={true}
                    content={{
                      standard: {
                        value: standardPricing.firstPart,
                        valueAfter: standardPricing.word + standardPricing.secondPart,
                      },
                      rocketFuel: {
                        value: rocketFuelPricing.firstPart,
                        valueAfter: rocketFuelPricing.word + rocketFuelPricing.secondPart,
                      },
                    }}
                  />
                </div>
              )
            }
            elementAfterIll={
              programAdditionalContent?.brandLink !== '' ? (
                <div className={styles['hero-brand-container']}>
                  <div className={styles['hero-brand-container__inner']}>
                    <p className={classNames(styles['hero-brand__text-1'], 'text text--body-2')}>
                      <span className={styles['hero-brand__text-1__part-1']}>{programName}</span> are{' '}
                      <b className={styles['hero-brand__text-1__part-2']}>‘{content.brandName}’</b>.
                    </p>
                    <p className={classNames(styles['hero-brand__text-2'], 'text text--h3')}>
                      <NavigationLink
                        sizeType="small-3"
                        iconRight={<ExternalLinkIcon style={{ fill: 'var(--color-main)' }} />}
                        path={programAdditionalContent?.brandLink}
                        target="_blank"
                        rel="nofollow"
                      >
                        Visit our landing page.
                      </NavigationLink>
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )
            }
          />

          <WhatIsAstronomicPopUp
            whatIsPopUpActive={isPopUpActive}
            changeWhatIsPopUpActiveState={togglePopUp}
            userRole={userRole}
          />

          <SectionDivider color="Background-Neutral-Secondary" />

          <ChildrenWithHeaderSection
            title={<TitleWithSecTitle title="How it Works" />}
            backgroundColor="Background-Neutral-Secondary"
          >
            <Grid
              itemsInRow={2}
              itemGroup={content?.howItWorksList?.map((item, i) => (
                <TitleWithIconAndTextCard
                  key={i}
                  title={item?.name}
                  text={item?.description}
                  icon={programAdditionalContent?.howItWorksSectionIll[i]}
                />
              ))}
            />

            {content?.logisticsList && content?.logisticsList.length <= 0 && (
              <div className={styles['pre-footer-button']}>
                <JoinForFreeButton />
              </div>
            )}
          </ChildrenWithHeaderSection>

          {content?.logisticsList && content?.logisticsList.length > 0 && (
            <ChildrenWithHeaderSection
              title={<TitleWithSecTitle title="Logistics" />}
              backgroundColor="Background-Neutral-Secondary"
            >
              <Grid
                itemsInRow={3}
                itemGroup={content?.logisticsList?.map((item, i) => (
                  <TitleWithIconAndTextCard
                    key={i}
                    title={item?.name}
                    text={item?.description}
                    icon={programAdditionalContent?.logisticsIll[i]}
                    cardVariation="var-1"
                  />
                ))}
              />

              <div className={styles['pre-footer-button']}>
                <JoinForFreeButton />
              </div>
            </ChildrenWithHeaderSection>
          )}
        </>
      ) : (
        <HeroSectionSkeleton
          title="Meet 5 relevant founders in your city"
          text="We’ll match you with 5 other founders and business owners by shared industry, business type, and revenue stage for topical discussions over hearty meals."
          secondaryTitle={'Dinner'}
          sectionVariation="var-1-a"
          sectionBackgroundColor="Background-Neutral-Primary"
        />
      )}
    </MainTemplate>
  );
};

// const illustrationRecord = {
//   ConnectProgramEnum.CONSTELLATION_DINNERS: 'asdf';
// }

export default with_user_role(ConnectDetailView);
