import { FunctionComponent } from 'react';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import MainButton from '../../components/buttons/MainButton/MainButton';
import RoutesEnum from '../../enums/routes';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';
import CalendlyWidget from '../../components/widgets/CalendlyWidget/CalendlyWidget';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import { GENERAL_CONTENT } from '../../data/generalContent';
import MetaTags from '../../components/seo/MetaTags/MetaTags';

interface ScheduleMeetingViewProps extends IWithUserRoleProps {}

const ScheduleMeetingView: FunctionComponent<ScheduleMeetingViewProps> = ({ user_role, router }) => {
  return (
    <MainTemplate>
      <MetaTags title={GENERAL_CONTENT.SCHEDULE_A_MEETING} />

      <ChildrenWithHeaderSection
        title={
          <SimpleTitle>
            <h2 className="text text--h1" style={{ textAlign: 'center' }}>
              {GENERAL_CONTENT.SCHEDULE_A_MEETING}
            </h2>
          </SimpleTitle>
        }
      >
        <CalendlyWidget dataUrl="https://calendly.com/astronomic/discovery" />
      </ChildrenWithHeaderSection>
    </MainTemplate>
  );
};

export default with_user_role(ScheduleMeetingView);
