import { AxiosInstance, AxiosResponse } from 'axios';
import BaseAPI from '../base/api';
import { ApiResponseDTO } from '../../dto/api';

class ContentApi extends BaseAPI {
  private paths = {
    GET_HOME_PAGE_CONTENT: (roleId: string) => `Content/HomePageContent/${roleId}`,

    GET_CONNECT_PROGRAMS_BY_ROLE: (roleId: string) => `Content/ConnectPrograms/${roleId}`,
    GET_CONNECT_PROGRAM_DETAILS: (programId: string) => `Content/ConnectProgram/${programId}`,

    GET_SERVICE_CATEGORIES: 'Content/ServiceCategories',
    GET_GROWTH_STAGES: 'Content/GrowthStages',
    GET_BUILD_SERVICES: 'Content/BuildServices',
    GET_BUILD_SERVICE_DETAILS: (serviceId: string) => `Content/BuildServiceDetails/${serviceId}`,

    GET_LEARN_MENTORS_BY_ROLE: (roleId: string) => `Content/LearnMentors/${roleId}`,
    GET_LEARN_MENTOR_DETAILS: (mentorId: string) => `Content/LearnMentorDetails/${mentorId}`,

    GET_FUND_RESOURCES_BY_ROLE: (roleId: string) => `Content/FundResources/${roleId}`,
    GET_FUND_RESOURCE_DETAILS_BY_ID: (resourceId: string) => `Content/FundResource/${resourceId}`,

    GET_GROW_LIST_BY_ROLE: (roleId: string) => `Content/GrowList/${roleId}`,
    GET_GROW_LIST_DETAILS: (resourceId: string) => `Content/GrowListDetails/${resourceId}`,
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(axios: AxiosInstance, backend: string) {
    super(axios, backend);
  }

  async getHomePageContent(roleId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_HOME_PAGE_CONTENT(roleId));
  }

  async getConnectProgramsByRole(roleId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_CONNECT_PROGRAMS_BY_ROLE(roleId));
  }

  async getConnectProgramInfo(programId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_CONNECT_PROGRAM_DETAILS(programId));
  }

  async getServiceCategories() {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_SERVICE_CATEGORIES);
  }

  async getGrowthStages() {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_GROWTH_STAGES);
  }

  async getServicesByCategoryAndStage(params: {
    roleId: string;
    disciplineId: Array<string> | null;
    stage: number | string | null;
  }) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_BUILD_SERVICES, {
      params,
      paramsSerializer: (params) => {
        // Custom serializer to repeat parameters without brackets
        const queryParams: string[] = [];
        for (const key in params) {
          if (Array.isArray(params[key])) {
            // Serialize array by repeating the key for each item
            params[key].forEach((item: string) => {
              queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
            });
          } else if (params[key] !== null && params[key] !== undefined) {
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
          }
        }
        return queryParams.join('&');
      },
    });
  }

  async getBuildServiceDetails(serviceId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_BUILD_SERVICE_DETAILS(serviceId));
  }

  async getLearnMentorsByRole(roleId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_LEARN_MENTORS_BY_ROLE(roleId));
  }

  async getLearnMentorsDetails(mentorId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_LEARN_MENTOR_DETAILS(mentorId));
  }

  async getFundResourcesByRole(roleId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_FUND_RESOURCES_BY_ROLE(roleId));
  }

  async getFundResourceById(resourceId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_FUND_RESOURCE_DETAILS_BY_ID(resourceId));
  }

  async getGrowListByRole(roleId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_GROW_LIST_BY_ROLE(roleId));
  }

  async getGrowListDetails(listId: string) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_GROW_LIST_DETAILS(listId));
  }
}

export default ContentApi;
