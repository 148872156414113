import { ChangeEvent, FunctionComponent, useState } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../../../asset/images/astronomic_logo_3.svg';
import classNames from 'classnames';
import styles from './ForgotPassword.module.scss';
import Input from '../../../../../components/inputs/Input/Input';
import { ReactComponent as MailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { isEmailValid } from '../../../../../utils/regex';
import InputMessage from '../../../../../components/texts/InputMessage/InputMessage';
import useFormSubmitLoader from '../../../../../hooks/useFormSubmitLoader';
import api from '../../../../../api';
import { ApiResponseDTO } from '../../../../../dto/api';
import { AxiosError } from 'axios';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';
import { localStorageSetItem } from '../../../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../../../enums/localStorageKeys';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import MetaTags from '../../../../../components/seo/MetaTags/MetaTags';
import { GENERAL_CONTENT } from '../../../../../data/generalContent';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

interface ForgotPasswordProps {}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const { isLoading, handleFormSubmitLoader } = useFormSubmitLoader();
  const isMobile = useMediaQuery('(max-width: 1279px)');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError(false);
    setSuccessMessage('');
    setErrorMessage('');
    setEmail(e.target.value);
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (email === '') {
      setErrorMessage('Enter your email');
      setEmailError(true);
      return;
    } else if (isEmailValid(email) === false) {
      setErrorMessage('Enter valid email');
      setEmailError(true);
      return;
    }

    await handleFormSubmitLoader(async () => {
      try {
        const response = await api.Auth.forgotPassword({ email: email });

        const responseData = response.data as ApiResponseDTO;

        if (responseData.success === true && responseData.data) {
          if (typeof responseData.data === 'string') {
            setSuccessMessage(responseData.data);
          } else {
            setSuccessMessage('Check your email for further steps');
          }

          callToast({
            title: 'Email sent!',
            children: (
              <>
                We've sent an email to <b>{email}</b> with instructions to change your password.
              </>
            ),
            variation: 'success',
          });
          setEmail('');
          localStorageSetItem(LOCAL_STORAGE_KEYS.USER_EMAIL, email);
        } else {
          console.error('Error:', response);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;
        setSuccessMessage('');

        if (errorData?.errorMessage) {
          setErrorMessage(errorData.errorMessage);
        }

        console.error('Error: ', error);
      }
    });
  };

  const { togglePopUp } =
    useOutletContext<{
      togglePopUp;
    }>();

  return (
    <div className={styles['container']}>
      <MetaTags title="Forgot password" />

      <div className={styles['container-top-part']}>
        <NavigationLink
          path="/"
          iconLeft={<ArrowIcon style={{ width: '11px', transform: 'rotate(180deg)' }} />}
          withBorder={false}
          colorType="white"
        >
          Back to Homepage
        </NavigationLink>
      </div>

      <div className={styles['content']}>
        <CSSTransitionWrapper onEnter={isLoading} styleVariation="onForeground">
          <AstronomicLoader variation="blurredBackground" color="onBlue" />
        </CSSTransitionWrapper>

        <div className={styles['top-row']}>
          <div className={styles['astronomic-logo']}>{<AstronomicLogo />}</div>
          {isMobile === true && (
            <MainButton sizeType="medium" visualType="white" onClick={togglePopUp}>
              {GENERAL_CONTENT.HOW_ASTRONOMIC_WORKS}
            </MainButton>
          )}
        </div>

        <div className={styles['title']}>
          <h3 className="text text--h3">Forgot Password</h3>
        </div>

        <form className={classNames(styles['form'], 'input-layout')} onSubmit={handleOnSubmit}>
          <div className="input-layout__row">
            <Input
              size="large"
              placeholder="Enter your email"
              color="gray"
              iconLeft={<MailIcon style={{ width: '22px' }} />}
              value={email}
              error={emailError}
              onChange={handleEmailOnChange}
            />

            {errorMessage && <InputMessage>{errorMessage}</InputMessage>}
            {successMessage && <InputMessage messageState="success">{successMessage}</InputMessage>}
          </div>

          <div className={classNames('input-layout__row input-layout__row--large-margin', styles['row-submit'])}>
            <div className={styles['form-button-group']}>
              <div className={styles['form-button-group__item']}>
                <MainButton
                  sizeType="large"
                  visualType="white"
                  onClick={() => navigate('/sign-in/')}
                  disabled={isLoading}
                >
                  Cancel
                </MainButton>
              </div>

              <div className={styles['form-button-group__item']}>
                <MainButton sizeType="large" visualType="main" disabled={isLoading} type="submit">
                  Request a reset code
                </MainButton>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className={styles['container-bottom-part']}></div>
    </div>
  );
};

export default ForgotPassword;
