import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

interface MetaTagsProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
}

const MetaTags: FunctionComponent<MetaTagsProps> = ({ title, description, image, url, type = 'website' }) => {
  const defaultTitle = 'Astronomic - Your One Stop Startup Shop 🚀';
  const defaultDescription =
    'Astronomic is your one stop startup shop. We help with each stage of growth, from strategy, to design, software, marketing, fundraising, hiring, data, and more.';
  const defaultImage = require('../../../asset/images/og_image.jpg');
  const defaultUrl = 'https://www.astronomic.com/';

  return (
    <Helmet>
      <title>{title ? `Astronomic - ${title}` : defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:url" content={url || defaultUrl} />
      <meta property="og:type" content={type} />
      <link rel="canonical" href={url || defaultUrl} />
    </Helmet>
  );
};

export default MetaTags;
