import { DetailedHTMLProps, FunctionComponent, InputHTMLAttributes, useEffect, useRef } from 'react';
import styles from './RangeSlider.module.scss';

interface RangeSliderProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  visualStepsCount?: number;
}

const RangeSlider: FunctionComponent<RangeSliderProps> = ({
  min,
  max,
  step,
  visualStepsCount = 0,
  value,
  onChange,
  style,
}) => {
  const sliderRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (sliderRef.current) {
      handleChange({
        target: sliderRef.current,
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempSliderValue = event.target.value;
    const progress = (Number(tempSliderValue) / Number(event.target.max)) * 100;
    event.target.style.background = `linear-gradient(to right, var(--color-main) ${progress}%, #dadbe2 ${progress}%)`;
  };

  return (
    <div className={styles['container']}>
      <input
        className={styles['slider']}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(event) => {
          if (onChange) {
            onChange(event);
          }

          handleChange(event);
        }}
        style={style}
        ref={sliderRef}
      />

      {visualStepsCount > 0 && (
        <div className={styles['steps']}>
          {Array.from({ length: visualStepsCount }, (_, index) => (
            <div className={styles['steps__item']} key={index}></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RangeSlider;
