import { FunctionComponent, useState, useEffect } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ReactComponent as MasterCardIcon } from '../../../asset/images/icons/mastercard.svg';
import { ReactComponent as VisaIcon } from '../../../asset/images/icons/visa.svg';
import { ReactComponent as AmericanExpressIcon } from '../../../asset/images/icons/american_express.svg';
import Input from '../../inputs/Input/Input';
import classNames from 'classnames';
import styles from './CreditCardForm.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import InputMessage from '../../texts/InputMessage/InputMessage';
import { hasOnlyEnglishCharacters } from '../../../utils/regex';
import api from '../../../api';
import { ApiResponseDTO } from '../../../dto/api';
import { AxiosError } from 'axios';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setPaymentMethodId, setStripeCustomerId } from '../../../redux/slices/user/slice';
import callToast from '../../blocks/ToastMessage/_parts/callToast/callToast';
import useFormSubmitLoader from '../../../hooks/useFormSubmitLoader';
import CSSTransitionWrapper from '../../../containers/wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../blocks/AstronomicLoader/AstronomicLoader';
import { StripeCardNumberElement } from '@stripe/stripe-js';

interface CreditCardFormProps {
  saveCreditCard(): void;
}

const globalCssStyles = getComputedStyle(document.body);
const stripeElementStyle = {
  base: {
    fontFamily: "'Axiforma', monospace",
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: 'initial',
    color: globalCssStyles.getPropertyValue('--color-black'),
    '::placeholder': {
      color: globalCssStyles.getPropertyValue('--Neutral-500'),
    },
  },
  invalid: {
    color: globalCssStyles.getPropertyValue('--color-red-400'),
  },
};

const CreditCardForm: FunctionComponent<CreditCardFormProps> = ({ saveCreditCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { isLoading, handleFormSubmitLoader } = useFormSubmitLoader();
  const [nameOnCard, setNameOnCard] = useState('');
  const [nameOnCardError, setNameOnCardError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useAppDispatch();
  const userEmail = useAppSelector((state) => state.user.user?.email);
  const stripeCustomerId = useAppSelector((state) => state.user.user?.stripeCustomerId);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameOnCard(e.target.value);
    setNameOnCardError(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setErrorMessage('');
    setNameOnCardError(false);

    if (!stripe || !elements) {
      return;
    }

    if (nameOnCard === '') {
      setErrorMessage('Enter your full name');
      setNameOnCardError(true);
      return;
    } else if (nameOnCard.length >= 20) {
      setErrorMessage('Maximum 20 characters');
      setNameOnCardError(true);
      return;
    } else if (nameOnCard.length <= 1) {
      setErrorMessage('Minimum 2 characters');
      setNameOnCardError(true);
      return;
    } else if (!hasOnlyEnglishCharacters(nameOnCard)) {
      setErrorMessage('Should contain only english characters');
      setNameOnCardError(true);
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);

    // stripe
    //   .createPaymentMethod({
    //     type: 'card',
    //     card: cardNumber!,
    //     billing_details: {
    //       name: nameOnCard,
    //     },
    //   })
    //   .then(function (result) {
    //     if (result.error) {
    //       console.error(result.error.message);
    //       setErrorMessage(result.error.message!);
    //     } else {
    //       if (!stripeCustomerId) {
    //         createCustomerWithDefaultPM(result.paymentMethod?.id);
    //       } else {
    //         updateCustomerDefaultPM(result.paymentMethod?.id);
    //       }
    //     }
    //   });

    await handleFormSubmitLoader(async () => {
      try {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumber!,
          billing_details: {
            name: nameOnCard,
          },
        });

        if (result.error) {
          console.error(result.error.message);
          setErrorMessage(result.error.message!);
        } else {
          if (!stripeCustomerId) {
            createCustomerWithDefaultPM(result.paymentMethod?.id);
          } else {
            updateCustomerDefaultPM(result.paymentMethod?.id);
          }

          dispatch(setPaymentMethodId(result.paymentMethod?.id));
        }
      } catch (error) {
        // console.error(error.message);
        // setErrorMessage(error.message);
        console.log(error);
      }
    });
  };

  const createCustomerWithDefaultPM = async (paymentMethodId) => {
    await handleFormSubmitLoader(async () => {
      try {
        const response = await api.Payment.createCustomerWithDefaultPM({
          email: userEmail!,
          name: nameOnCard,
          paymentMethodId: paymentMethodId,
        });

        const responseData = response.data;

        if (responseData.success === true && responseData.data) {
          callToast({
            variation: 'success',
            title: 'Billing details',
            children: <>Your payment method was successfully added</>,
          });
          dispatch(setStripeCustomerId(responseData.data.stripeCustomerId));
          saveCreditCard();
        } else {
          callToast({
            error: responseData.errors,
            children: <>{responseData.errorMessage}</>,
          });
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;

        callToast({
          error: error,
          children: <>{errorObj.response?.data.errorMessage}</>,
        });
      }
    });
  };

  const updateCustomerDefaultPM = async (paymentMethodId) => {
    await handleFormSubmitLoader(async () => {
      try {
        const response = await api.Payment.updateCustomerDefaultPaymentMethod({
          email: userEmail!,
          name: nameOnCard,
          paymentMethodId: paymentMethodId,
        });

        const responseData = response.data;

        if (responseData.success === true && responseData.data) {
          callToast({
            variation: 'success',
            title: 'Billing details',
            children: <>Your default payment method was successfully updated</>,
          });
          saveCreditCard();
        } else {
          callToast({
            error: responseData.errors,
            children: <>{responseData.errorMessage}</>,
          });
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;

        callToast({
          error: error,
          children: <>{errorObj.response?.data.errorMessage}</>,
        });
      }
    });
  };

  useEffect(() => {
    let cardNumber: StripeCardNumberElement | null = null;

    if (elements) {
      cardNumber = elements.getElement(CardNumberElement);
    }

    if (cardNumber) {
      cardNumber.on('ready', () => {
        console.log('Stripe is ready');
      });
    }
  }, [elements]);

  return (
    <form className={classNames(styles['form'], 'input-layout')} onSubmit={handleSubmit}>
      <CSSTransitionWrapper onEnter={isLoading} styleVariation="onForeground">
        <AstronomicLoader variation="blurredBackground" />
      </CSSTransitionWrapper>

      <div className="tile tile--no-shadow tile--padding-32px">
        <div
          className={classNames(
            styles['name-and-credit-cards-row'],
            'input-layout__row input-layout__row--flexible-col'
          )}
        >
          <div className={classNames(styles['name-col'], 'input-layout__row__col')}>
            <Input
              value={nameOnCard}
              label="Name on card"
              placeholder="Name on card"
              size="large"
              error={nameOnCardError}
              onChange={handleInputChange}
            />
          </div>

          <div className={classNames(styles['credit-card-company-col'], 'input-layout__row__col')}>
            <div className={styles['credit-card-company-group']}>
              <div className={styles['credit-card-company-group__item']}>
                <MasterCardIcon />
              </div>

              <div className={styles['credit-card-company-group__item']}>
                <VisaIcon />
              </div>

              <div className={styles['credit-card-company-group__item']}>
                <AmericanExpressIcon />
              </div>
            </div>
          </div>
        </div>

        <div className="input-layout__row input-layout__row--flexible-col">
          <div className="input-layout__row__col">
            <label>
              <p className={classNames(styles['label'], 'text text--body-2 text--bold')}>Card Number</p>

              <div className={styles['stripe-element-container']}>
                <CardNumberElement
                  options={{
                    placeholder: '0000 0000 0000 0000',
                    style: stripeElementStyle,
                  }}
                />
              </div>
            </label>
          </div>

          <div className={classNames(styles['small-col'], 'input-layout__row__col')}>
            <label>
              <p className={classNames(styles['label'], 'text text--body-2 text--bold')}>Expiry Date</p>

              <div className={styles['stripe-element-container']}>
                <CardExpiryElement
                  options={{
                    style: stripeElementStyle,
                  }}
                />
              </div>
            </label>
          </div>

          <div className={classNames(styles['small-col'], 'input-layout__row__col')}>
            <label>
              <p className={classNames(styles['label'], 'text text--body-2 text--bold')}>CVC</p>

              <div className={styles['stripe-element-container']}>
                <CardCvcElement
                  options={{
                    style: stripeElementStyle,
                  }}
                />
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className={classNames('input-layout__row input-layout__row--medium-margin', styles['row-submit'])}>
        <MainButton sizeType="large" visualType="main" disabled={isLoading}>
          Save Credit Card
        </MainButton>

        {errorMessage && <InputMessage>{errorMessage}</InputMessage>}
      </div>
    </form>
  );
};

export default CreditCardForm;
