import { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import styles from './RadioSwitch.module.scss';

interface RadioSwitchProps {
  options: { value: string; label: string }[];
  name: string;
  selected: string; // Controlled by parent
  onChange: (value: string) => void; // Notifies parent
}

const RadioSwitch: FunctionComponent<RadioSwitchProps> = ({ options, name, selected, onChange }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['options']}>
        {options.map((option) => (
          <label className={styles['options__item']} key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={selected === option.value}
              onChange={() => onChange(option.value)}
            />

            <div className={styles['options__item__bg']}></div>
            <span className={styles['options__item__label']}>{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioSwitch;
